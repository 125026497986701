import urlJoin from "url-join";
import {
  FEET_TO_METERS,
  INCHES_TO_METERS,
  CUBIC_FEET_TO_METERS,
  SQUARE_FEET_TO_METERS,
  CENTIMETERS_TO_INCHES,
} from "sf/consts/geometry";
import {
  SECOND,
  SECONDS_IN_DAY,
  SECONDS_IN_HOUR,
  SECONDS_IN_YEAR,
  SECONDS_IN_MONTH,
  SECONDS_IN_MINUTE,
} from "sf/consts/dateTime";

export const DEFAULT_API_LIMIT = 100;

export const publicUrl = (path) => urlJoin(process.env.PUBLIC_URL || "/", path);

export const radiansToDegrees = (rad) => {
  return rad * (180 / Math.PI);
};

export const degreesToRadians = (deg) => {
  return deg * (Math.PI / 180);
};

export const getDistance = (p1, p2) => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};

export const feetsToMetrics = (val) => {
  return val / FEET_TO_METERS;
};

export const squareFeetsToMetricsSq = (val) => {
  return val / SQUARE_FEET_TO_METERS;
};
export const cubicFeetsToCubicMeter = (val) => {
  return val / CUBIC_FEET_TO_METERS;
};

export const meterToFeets = (val) => {
  return val * FEET_TO_METERS;
};

export const meterToInches = (val) => {
  return val * INCHES_TO_METERS;
};

export const cmeterToInches = (val) => {
  return val * CENTIMETERS_TO_INCHES;
};

// from https://gist.github.com/seajean/f6464baf9ae463df1e4f
export function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / SECOND);

  let interval = Math.floor(seconds / SECONDS_IN_YEAR);

  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / SECONDS_IN_MONTH);
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / SECONDS_IN_DAY);
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / SECONDS_IN_HOUR);
  if (interval > 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / SECONDS_IN_MINUTE);
  if (interval > 1) {
    return interval + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

// from https://github.com/sindresorhus/array-move
export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable(array, fromIndex, toIndex) {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
}

// from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export function humanFileSize(bytes, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export function downloadCSV(csvString = "", fileName = "drawing_log.csv") {
  const blob = new Blob([csvString], { type: "text/csv" });

  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
