import kinks from "@turf/kinks";

import { generateId } from "../../utils/string";
import { CommonDrawMode } from "./common-draw";
import { head } from "../../utils/functional/list";
import { GEOJSON_TYPES } from "../../consts/editor";
import { makeMarkupFeature } from "../base/ImmutableLayersData";

import { getMarkupFeatureContextProps } from "../../utils/geometry";

export class DrawMarkupPolygonMode extends CommonDrawMode {
  handleAddFeature = (event: any, props: any) => {
    const features = props.data.features;
    const clickSequence = this.getClickSequence();

    if (clickSequence.length > 2) {
      const mainCoordinates = this.mainFeature?.properties?.closed
        ? this.mainFeature.geometry.coordinates[0]
        : clickSequence;
      const coordinates: any = [[...mainCoordinates, head(mainCoordinates)]];

      const options = props?.modeConfig?.options;

      const featureId = generateId();
      const feature: any = {
        type: GEOJSON_TYPES.Feature,
        properties: {
          id: featureId,
          text: options.text,
          size: options.size.default,
          color: options.color.default,
          border: options.border.default,
          thinkness: options?.thinkness?.default || 0,
          opacity: options.color.default.fillOpacity === 0 ? 0 : 50,
          arcs: this._arcs,
        },
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates,
        },
      };

      const featureKinks = kinks(feature);
      const isEdgeIntersection = featureKinks?.features?.length > 1;

      if (
        (isEdgeIntersection && !this.mainFeature?.properties?.closed) ||
        this._lock
      ) {
        return;
      }

      const markupFeature = makeMarkupFeature({
        feature,
        name: "",
        type: props.modeConfig.modeId,
      });

      const contextProps = getMarkupFeatureContextProps(
        markupFeature,
        props.modeConfig.deck.current.deck
      );

      this.addFeatures([markupFeature]);
      this.applyActions(props, true, {
        contextProps,
      });

      this._resetState();
    }
  };
}
