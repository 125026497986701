import { CommonDrawMode } from "./common-draw";
import { GEOJSON_TYPES } from "../../consts/editor";
import {
  getFeatureMeasures,
  makeMarkupFeature,
} from "../base/ImmutableLayersData";

import { generateId } from "../../utils/string";
import { head } from "../../utils/functional/list";

export class DrawDimensionLineMode extends CommonDrawMode {
  handleAddFeature = (_event: any, props: any) => {
    const clickSequence = this.getClickSequence();

    if (clickSequence.length > 0) {
      const coordinates: any = this?.mainFeature?.geometry?.coordinates;
      const cleanedCoords =
        coordinates[0]?.length && Array.isArray(head(coordinates[0]))
          ? coordinates[0]
          : coordinates;

      const markupFeatures = [];

      for (const idx in cleanedCoords) {
        const index = Number(idx);
        if (index > 0) {
          const newId = generateId();

          const options = props?.modeConfig?.options;

          const feature = {
            type: GEOJSON_TYPES.Feature,
            properties: {
              id: newId,
              text: options.text,
              size: options.size.default,
              color: options.color.default,
              border: options.border.default,
              thinkness: options?.thinkness?.default || 0,
              opacity: options.color.default.fillOpacity === 0 ? 0 : 50,
            },
            geometry: {
              type: GEOJSON_TYPES.LineString,
              coordinates: [cleanedCoords[index - 1], cleanedCoords[index]],
            },
          };

          const markupFeature = makeMarkupFeature({
            feature,
            name: "",
            type: props.modeConfig.modeId,
          });

          const markupFeatureMeasures = getFeatureMeasures(
            markupFeature,
            props.modeConfig.view,
            props.modeConfig.colorMap
          );

          markupFeatures.push({
            ...markupFeature,
            properties: {
              ...markupFeature.properties,
              ...markupFeatureMeasures,
            },
          });
        }
      }

      props.modeConfig.setSelected([markupFeatures[0].properties.id]);
      this.addFeatures(markupFeatures);

      this.applyActions(props, true, {
        positions: cleanedCoords,
        editType: "addDimentionLine",
        featureIds: markupFeatures.map((f) => f.properties.id),
      });

      this._resetState();
    }
  };
}
