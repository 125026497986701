import { CSSProperties } from "react";
import styled, { css } from "styled-components";

import { addOpacity } from "../../utils/style";
import withPortal from "../../hoc/withPortal";
import { CSS, FONT_WEIGHTS } from "../../consts/style";

/**
 * Contains a set of common styled components used throughout the project
 */
interface IColor {
  bgColor?: CSSProperties["backgroundColor"];
}

interface IFlex {
  align?: CSSProperties["alignItems"];
  flex?: CSSProperties["flexDirection"];
  gap?: CSSProperties["gap"];
  grow?: CSSProperties["flexGrow"];
  shrink?: CSSProperties["flexShrink"];
  justify?: CSSProperties["justifyContent"];
}

interface IFont {
  fontColor?: CSSProperties["color"];
  fontSize?: CSSProperties["fontSize"];
  lineHeight?: CSSProperties["lineHeight"];
  fontWeight?: CSSProperties["fontWeight"];
  textDecoration?: CSSProperties["textDecoration"];
}

interface IVisibility {
  opacity?: CSSProperties["opacity"];
}

interface IBox {
  margin?: CSSProperties["margin"];
  height?: Exclude<CSSProperties["height"], number>; // SC doesn't accept CSSProperties["height"] because it's string | number and SC expects a string
  maxHeight?: CSSProperties["maxHeight"];
  maxWidth?: CSSProperties["maxWidth"];
  minHeight?: CSSProperties["minHeight"];
  minWidth?: CSSProperties["minWidth"];
  padding?: CSSProperties["padding"];
  width?: Exclude<CSSProperties["width"], number>; // SC doesn't accept CSSProperties["width"] because it's string | number and SC expects a string
  overflow?: CSSProperties["overflow"];
}

interface IShadow {
  shadow?: boolean;
}

interface IBorder {
  border?: CSSProperties["border"];
  borderColor?: CSSProperties["borderColor"];
  borderRadius?: CSSProperties["borderRadius"];
  borderSize?: CSSProperties["borderWidth"];
}

interface IText {
  fontStyle?: CSSProperties["fontStyle"];
  whiteSpace?: CSSProperties["whiteSpace"];
  textAlign?: CSSProperties["textAlign"];
  wordBreak?: CSSProperties["wordBreak"];
}

interface IPosition {
  left?: CSSProperties["left"];
  right?: CSSProperties["right"];
  top?: CSSProperties["top"];
}

interface ITooltip {
  coords: {
    top?: number;
    left?: number;
    right?: number;
  };
  maxWidth: string;
  type: string;
}

export interface IStyles
  extends IBorder,
    IBox,
    IColor,
    IFlex,
    IFont,
    IPosition,
    IVisibility,
    IShadow,
    IText {}

export const Container: any = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
`;

export const PopupContainer: any = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 100;
`;

interface IClassificationSection {
  visible?: boolean;
}

export const ClassificationSection: any = styled.div<IClassificationSection>`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.visible ? "1" : "0")};
  position: absolute;
  width: 240px;
`;

export const Flex: any = styled.div<IStyles>`
  display: flex;
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ gap }) => gap && `gap: ${gap};`};
  ${({ grow }) =>
    grow !== undefined &&
    `flex-grow: ${grow};`}; // undefined check so 0 doesn't fail
  ${({ shrink }) =>
    shrink !== undefined &&
    `flex-shrink: ${shrink};`}; // undefined check so 0 doesn't fail
  flex-direction: ${(props) => props.flex || "row"};
  align-items: ${(props) => props.align || "center"};
  justify-content: ${(props) => props.justify || "center"};
  background-color: ${(props) => props.bgColor || "transparent"};
  width: ${(props) => props.width || "100%"};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
  height: ${(props) => props.height || "100%"};
  ${({ maxHeight }) =>
    maxHeight && `max-height: ${maxHeight}; overflow-y: auto`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`}
  ${({ overflow }) => overflow && `overflow: ${overflow};`};
`;

export const PointerBox: any = styled.div<IStyles>`
  cursor: pointer;
  user-select: none;
  display: flex;
  ${({ grow }) =>
    grow !== undefined &&
    `flex-grow: ${grow};`}; // undefined check so 0 doesn't fail
`;

export const BoxContainer: any = styled.div<IStyles>`
  max-width: 400px;
  width: calc(100vw - 40px);
  min-height: 550px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  border-radius: ${CSS.BORDER_RADIUS};
  background-color: ${(props) => props.color || CSS.WHITE};
  box-shadow: ${CSS.MAINCOLOR};
  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0 5px 20px 0 rgba(62, 62, 62, 0.1);
    `};
`;

export const Para: any = styled.p<IStyles>`
  margin: ${(props) => props.margin || "0"};
  ${(props) => props.width && `width: ${props.width};`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`};
  padding: ${(props) => props.padding ?? 0};
  color: ${(props) => props.fontColor || "inherit"};
  ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration};`}
  font-size: ${(props) => props.fontSize || CSS.FONT_DEFAULT};
  line-height: ${(props) =>
    props.lineHeight ?? props.fontSize ?? CSS.FONT_DEFAULT};
  font-weight: ${(props) => props.fontWeight || FONT_WEIGHTS.REGULAR};
  ${({ onClick }) => onClick && `cursor: pointer`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
  ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak};`}
`;

export const ParaWithEllipsis: any = styled(Para)<IStyles>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Tooltip: any = withPortal(styled.div<IStyles & ITooltip>`
  position: absolute;
  top: ${({ coords }) => coords.top && coords.top + "px"};
  left: ${({ coords }) => coords.left && coords.left + "px"};
  right: ${({ coords }) => coords.right && coords.right + "px"};
  z-index: 1000000;

  padding: ${(props) => props.padding + "px" ?? 0};
  max-width: ${(props) => props.maxWidth || "300px"};
  overflow-wrap: break-word;

  border-radius: ${CSS.BORDER_RADIUS};
  box-shadow: ${CSS.BOX_SHADOW};
  user-select: none;

  ${({ type }) =>
    type === "functionality"
      ? css`
          padding: 4px 6px;
          background-color: ${CSS.BLACK};
          color: ${CSS.WHITE};
          font-weight: ${FONT_WEIGHTS.BOLD};
        `
      : type === "warning"
      ? css`
          padding: 10px;
          color: ${CSS.WHITE};
          background: ${CSS.WARNING};
        `
      : css`
          background-color: ${CSS.WHITE};
          color: ${addOpacity(CSS.BLACK, 70)};
          font-weight: ${FONT_WEIGHTS.REGULAR};
          line-height: ${CSS.LINE_HEIGHT_SMALL};
        `}
`);

export const DefaultInputField: any = styled.input<IStyles>`
  background-color: ${(props) => props.bgColor || CSS.WHITE};
  height: 40px;
  border-radius: ${CSS.BORDER_RADIUS};
  border: 2px solid ${CSS.GREY};
  font-weight: 300;
  width: calc(100% - 160px);
  padding: 0.35em 2em;
  margin: 10px;
  color: black;
  font-size: 1em;
  &:focus {
    outline: none;
  }
  :hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }
  &:disabled {
    background: ${CSS.GREY};
  }
`;

export const HorizontalSep: any = styled.div`
  width: 1px;
  min-height: 20px;
  height: 100%;
  background-color: ${({ color }) => (color ? color : CSS.GREY)};
`;

export const VerticalSep: any = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ color }) => (color ? color : CSS.GREY)};
`;

export const SepContainer: any = styled.div`
  width: 100%;
  height: 60px;
`;

export const Separator: any = styled.div<IStyles>`
  flex-shrink: 0;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
`;
