import bbox from "@turf/bbox";
import center from "@turf/center";
import bboxPolygon from "@turf/bbox-polygon";
import turfTransformRotate from "@turf/transform-rotate";

import Document from "flexsearch/dist/module/document";

import {
  TAKE_OFFS,
  GEOJSON_TYPES,
  TAKE_OFF_TYPES,
  DEFAULT_FOLDERS_IDS,
  CLASSIFICATION_TYPES,
  DEFAULT_CLASSIFICATION,
  DEFAULT_FOLDERS_IDS_SET,
  DEFAULT_TEXT_FEATURES_ID,
  CLASSIFICATION_CATEGORIES,
  DEFAULT_CLASSIFICATION_IDS,
  DEFAULT_CLASSIFICATION_MAP,
  CLS_COLORS,
  DEFAULT_FOLDERS_MAP,
} from "sf/consts/editor";
import { generateId } from "sf/utils/string";
import { getUniqueArray } from "sf/utils/array";
import { getFeaturesUnitSum } from "sf/utils/metrics";
import { QUANTITIES, UNIT_FEET, UNIT_METER } from "sf/consts/classifications";

import { isString } from "lodash";
import { SORT_TYPES } from "./layersState";
import { cleanDuplicates } from "sf/utils/data";
import { radiansToDegrees } from "src/lib/utils";
import { DEFAULT_PADDING } from "sf/consts/coordinates";
import { DEFAULT_MULTIPLIER } from "src/constants/export";
import { COMBINED_VIEW, VIEW_PREFIX } from "sf/permissions";
import { updateGeometryWithPadding } from "sf/utils/coordinates";
import { scaleUpFeature } from "src/UtilComponents/DataManager/dataUtils";
import { noop } from "sf/utils/function";
import { applyAtomicOperations } from "sf/utils/geometry";

/**
 *
 *
 * CLASSIFICATIONS UTILS
 *
 *
 */

export function flattenClassifications(classification = []) {
  return (
    classification
      ?.map((cls) => (cls?.children?.length > 0 ? cls.children : cls))
      ?.flat() || classification
  );
}

export function getClassificationsList(clMap) {
  return Object.keys(clMap).map((cl) => clMap[cl]);
}

export function getClassificationsByID(classifications) {
  return Object.fromEntries(
    flattenClassifications(classifications)
      .flat()
      .map((cl) => [cl.id, cl])
  );
}

export function getClassificationsByIDWihDefaults(
  classifications,
  returnFolder = true
) {
  const clMap = Object.fromEntries(
    flattenClassifications(classifications)
      .flat()
      .map((cl) => [cl.id, cl])
  );

  DEFAULT_CLASSIFICATION_IDS.forEach((clId) => {
    clMap[clId] = clId;
  });

  if (returnFolder) {
    DEFAULT_FOLDERS_IDS.forEach((clId) => {
      clMap[clId] = clId;
    });
  }

  return clMap;
}

export function getClassificationsForExport(clMap, useMetrics = false) {
  const existingClassifications = Object.keys(clMap).map((cl) => clMap[cl]);

  DEFAULT_CLASSIFICATION_IDS.forEach((clId) => {
    existingClassifications.push(DEFAULT_CLASSIFICATION_MAP[clId]);
  });

  DEFAULT_FOLDERS_IDS.forEach((clId) => {
    existingClassifications.push(DEFAULT_FOLDERS_MAP[clId]);
  });

  const cleanedExistingClassifications = cleanDefaultClassificationUnits(
    existingClassifications,
    useMetrics
  );

  return getClassificationsByID(cleanedExistingClassifications);
}

export function updateClassificationById(classifications, classification) {
  return classifications.map((cls) => {
    if (cls.id === classification.id) {
      return classification;
    } else {
      return {
        ...cls,
        children: cls.children.map((cl) =>
          cl.id === classification.id ? classification : cl
        ),
      };
    }
  });
}

export function removeClassificationById(classifications, classificationId) {
  if (!classificationId) return classifications;

  return classifications
    .map((cls) => ({
      ...cls,
      children: cls.children.filter((clm) => clm.id !== classificationId),
    }))
    .filter((cls) => cls.id !== classificationId);
}

export function processFolder(folder, parentId, flatCls) {
  const groupClassifications = flatCls?.filter(
    (flatCl) => flatCl.folderId === folder.id
  );

  const newFolder = {
    ...folder,
    id: generateId(),
    visible: false,
    isStatic: false,
    category: CLASSIFICATION_CATEGORIES[2],
    folderId: null,
    parent_folder_id: parentId,
    children: [],
  };

  const nestedFolders =
    folder.children?.filter(
      (child) => child.category === CLASSIFICATION_CATEGORIES[2]
    ) || [];

  nestedFolders.forEach((childFolder) => {
    const processed = processFolder(childFolder, newFolder.id, flatCls);
    newFolder.children.push(processed);
  });

  groupClassifications.forEach((cls) => {
    newFolder.children.push({
      ...cls,
      id: generateId(),
      isStatic: false,
      visible: false,
      folderId: newFolder.id,
    });
  });

  return newFolder;
}

export function addClassification(
  classifications,
  isFolder,
  classification,
  groupId
) {
  if (!classification || !classification.id) return classifications;

  if (isFolder) {
    return classifications.map((cl) => {
      if (cl.id === groupId) {
        return {
          ...cl,
          children: [...cl.children, classification],
        };
      }
      return cl;
    });
  }
  return [...classifications, classification];
}

export function getBreakdownsByID(breakdowns) {
  const breakdownsByID = {};

  breakdowns
    .map(function flattenChildren(br) {
      return br.children.length > 0
        ? [br, ...br.children.map(flattenChildren)]
        : br;
    })
    .flat()
    .flat()
    .forEach((br) => {
      breakdownsByID[br.id] = br;
    });

  return breakdownsByID;
}

/**
 *
 *
 * VIEW UTILS
 *
 *
 */

export function getViewsFromPages(pages) {
  return (pages || [])
    .map((p) => p?.views || [])
    .flat()
    .filter(Boolean);
}

export function getCombinedViews(views, user) {
  const viewsWithoutCombined = views.filter(
    (v) => !v?.id?.includes(COMBINED_VIEW)
  );
  const uniquePageIds = getUniqueArray(
    viewsWithoutCombined?.map((v) => v?.page?.id)?.filter(Boolean)
  );
  const combinedViews = [];
  for (const pageId of uniquePageIds) {
    const pageViews = viewsWithoutCombined.filter(
      (v) => v?.page?.id === pageId
    );
    const userView = pageViews?.find((v) => v.user_id === user.id);
    const combinedView = {
      ...(userView || pageViews[0]),
      id: COMBINED_VIEW + pageId,
      name: COMBINED_VIEW + VIEW_PREFIX,
      ownerId: userView?.id || pageViews[0].id,
      features: pageViews
        .map((l) =>
          l.features.map((fe) => ({
            ...fe,
            properties: {
              ...fe.properties,
              id: generateId(),
            },
          }))
        )
        .flat(),
    };
    combinedViews.push(combinedView);
  }
  return combinedViews;
}

/**
 *
 *
 * HISTORY UTILS
 *
 *
 */

export function handleUndoHistory(features, history, setHistory, cb = noop) {
  if (history.historyIdx !== 0) {
    const newHistoryIdx = history.historyIdx - 1;
    const historyIndexContext = history.history[newHistoryIdx];

    const undoHistoryContext = {
      addFeatures: historyIndexContext.deleteFeatures,
      deleteFeatures: historyIndexContext.addFeatures,
      editFeatures: historyIndexContext.editFeaturesBefore,
    };

    const newFeatures = applyAtomicOperations(features, undoHistoryContext);

    const newHistory = {
      ...history,
      historyIdx: newHistoryIdx,
    };
    setHistory(newHistory);

    cb({ features: newFeatures, context: undoHistoryContext });
  }
}

export function handleRedoHistory(features, history, setHistory, cb = noop) {
  if (history.history.length && history.historyIdx !== history.history.length) {
    const historyIndexContext = history.history[history.historyIdx];
    let newHistoryIdx = history.historyIdx + 1;

    const newFeatures = applyAtomicOperations(features, historyIndexContext);

    const newHistory = {
      ...history,
      historyIdx: newHistoryIdx,
    };
    setHistory(newHistory);

    cb({
      features: newFeatures,
      context: historyIndexContext,
    });
  }
}

/**
 *
 *
 * SEARCH UTIL
 *
 *
 */

export function createSearchIndex(data, searchProps = {}) {
  const text_features = data.map((f) => ({
    text: f?.properties?.str,
    id: f?.properties?.id,
    name: f?.properties?.name || "text",
  }));

  const index = new Document({
    cache: 100,
    id: "id",
    optimize: true,
    tokenize: "full",
    ...searchProps,
  });

  for (const feature of text_features) {
    index.add(feature);
  }

  return index;
}

/**
 *
 *
 * OTHER UTILS
 *
 *
 */

export function snapRotations(rad) {
  const deg = radiansToDegrees(rad);

  if (Math.abs(Math.round(deg)) === 90) {
    return deg < 0 ? -Math.PI / 2 : Math.PI / 2;
  } else if (Math.abs(Math.round(deg)) === 180) {
    return deg < 0 ? -Math.PI : Math.PI;
  }

  return 0;
}

export function pickMultiplier(view) {
  return (
    (view?.id?.includes(COMBINED_VIEW)
      ? view?.metadata?.combined_multiplier
      : view?.metadata?.multiplier) || DEFAULT_MULTIPLIER
  );
}

export function updateTabTitle(page, currentProjectName) {
  const pageName = page?.metadata?.cv_name || page?.name;
  const pageNumber = page?.metadata?.cv_number
    ? page.metadata.cv_number + `:`
    : "";
  const pageFullName = `${pageNumber} ${pageName}`;

  if (currentProjectName && pageFullName) {
    document.title = `${currentProjectName} / ${pageFullName}`;
  }
}

/**
 *
 *
 * DATA UTILS
 *
 */

export function getFeatureType(feature) {
  if (
    feature.properties.types.includes(
      TAKE_OFF_TYPES[TAKE_OFFS.WITHOUT_BOUNDARIES].id
    ) ||
    feature.properties.types.includes(
      TAKE_OFF_TYPES[TAKE_OFFS.WITH_BOUNDARIES].id
    )
  ) {
    return CLASSIFICATION_TYPES[0];
  } else if (
    feature.properties.types.includes(
      TAKE_OFF_TYPES[TAKE_OFFS.JUST_BOUNDARIES].id
    )
  ) {
    return CLASSIFICATION_TYPES[1];
  } else if (
    feature.properties.types.includes(TAKE_OFF_TYPES[TAKE_OFFS.COUNT].id)
  ) {
    return CLASSIFICATION_TYPES[2];
  }

  return null;
}

export function getFeaturesUnitNumericSum(features, useMetrics) {
  return Number(
    getFeaturesUnitSum(features, useMetrics, false).replace(/[^0-9.]/g, "")
  );
}

/**
 *
 *
 * CLASSIFICATIONS UTILS
 *
 */

export const getDefaultClassification = (useMetrics = false) => {
  if (!useMetrics) return DEFAULT_CLASSIFICATION;

  return DEFAULT_CLASSIFICATION.map((cls) => ({
    ...cls,
    quantity1UOM: QUANTITIES[cls.quantity1].defaultMetricUnit.id,
    quantity2UOM: QUANTITIES[cls.quantity2]?.defaultMetricUnit.id || null,
    quantity3UOM: QUANTITIES[cls.quantity3]?.defaultMetricUnit.id || null,
    quantity4UOM: QUANTITIES[cls.quantity4]?.defaultMetricUnit.id || null,
    inputGridUOM: UNIT_METER,
    inputWidthUOM: UNIT_METER,
    inputLengthUOM: UNIT_METER,
    inputHeightUOM: UNIT_METER,
    inputOffsetUOM: UNIT_METER,
    inputThicknessUOM: UNIT_METER,

    children: cls.children.map((child) => ({
      ...child,
      quantity1UOM: QUANTITIES[child.quantity1].defaultMetricUnit.id,
      quantity2UOM: QUANTITIES[child.quantity2]?.defaultMetricUnit.id || null,
      quantity3UOM: QUANTITIES[child.quantity3]?.defaultMetricUnit.id || null,
      quantity4UOM: QUANTITIES[child.quantity4]?.defaultMetricUnit.id || null,
      inputGridUOM: UNIT_METER,
      inputWidthUOM: UNIT_METER,
      inputLengthUOM: UNIT_METER,
      inputHeightUOM: UNIT_METER,
      inputOffsetUOM: UNIT_METER,
      inputThicknessUOM: UNIT_METER,
    })),
  }));
};

export const cleanDefaultClassificationUnits = (
  classifications,
  useMetrics = false
) => {
  return classifications.map((cls) =>
    cls.folderId && DEFAULT_FOLDERS_IDS_SET.has(cls.folderId)
      ? {
          ...cls,
          quantity1UOM: useMetrics
            ? QUANTITIES[cls.quantity1].defaultMetricUnit.id
            : QUANTITIES[cls.quantity1].defaultUnit.id,
          inputGridUOM: useMetrics ? UNIT_METER : UNIT_FEET,
          inputWidthUOM: useMetrics ? UNIT_METER : UNIT_FEET,
          inputLengthUOM: useMetrics ? UNIT_METER : UNIT_FEET,
          inputHeightUOM: useMetrics ? UNIT_METER : UNIT_FEET,
          inputOffsetUOM: useMetrics ? UNIT_METER : UNIT_FEET,
          inputThicknessUOM: useMetrics ? UNIT_METER : UNIT_FEET,

          children: cleanDefaultClassificationUnits(cls.children, useMetrics),
        }
      : cls
  );
};

function sortRecursively(features, compareFn, parentIndex = null) {
  return features
    .slice()
    .sort(compareFn)
    .map((f, index) => {
      const gridIndex =
        parentIndex !== null ? `${parentIndex}-${index + 1}` : `${index}`;

      return {
        ...f,
        gridIndex,
        children: f?.children?.length
          ? sortRecursively(f.children, compareFn, gridIndex)
          : [],
      };
    });
}

export function sortAll(features, sortKey, reverse = false) {
  switch (sortKey) {
    case SORT_TYPES.default.key:
      return features;
    case SORT_TYPES.alpha.key:
      return sortRecursively(features, (f1, f2) => {
        let comparison = f1.label.localeCompare(f2.label);
        return reverse ? comparison : -comparison;
      });

    case SORT_TYPES.unit.key:
    case SORT_TYPES.unit2.key:
    case SORT_TYPES.unit3.key:
    case SORT_TYPES.unit4.key:
      return sortRecursively(features, (f1, f2) => {
        const f1Q = parseFloat(f1[sortKey].replace(",", "") || 0) || 0;
        const f2Q = parseFloat(f2[sortKey].replace(",", "") || 0) || 0;
        let comparison = f1Q - f2Q;

        return reverse ? -comparison : comparison;
      });
    case SORT_TYPES.classificationCustomId.key:
      return sortRecursively(features, (f1, f2) => {
        let comparison = (f1?.data?.classificationCustomId || "").localeCompare(
          f2?.data?.classificationCustomId || ""
        );
        return reverse ? comparison : -comparison;
      });

    default:
      return features;
  }
}

export function rotateFeatures(view, features, rotation) {
  if (rotation === 0) return features;
  const imgWidth = view?.page?.url_width || view?.url_width;
  const imgHeight = view?.page?.url_height || view?.url_height;

  const bboxCoords = [
    [0, 0],
    [imgWidth, 0],
    [imgWidth, imgHeight],
    [0, imgHeight],
    [0, 0],
  ];

  let bboxFeature = {
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [bboxCoords],
    },
  };
  bboxFeature = updateGeometryWithPadding(DEFAULT_PADDING)(bboxFeature);
  let scaledFeatures = features.map(updateGeometryWithPadding(DEFAULT_PADDING));

  const centerPt = center({
    type: GEOJSON_TYPES.FeatureCollection,
    features: [bboxFeature],
  });
  const deg = radiansToDegrees(rotation);

  let rotatedLayers = turfTransformRotate(
    {
      type: GEOJSON_TYPES.FeatureCollection,
      features: scaledFeatures,
    },
    -deg,
    {
      pivot: centerPt,
    }
  );

  scaledFeatures = rotatedLayers?.features.map(
    updateGeometryWithPadding(1 / DEFAULT_PADDING)
  );

  return scaledFeatures;
}

function findFeatureMatch(feature, targetFeatures, featuresMap) {
  const featureBbox = featuresMap?.has(feature.properties.id)
    ? featuresMap.get(feature.properties.id).bbox
    : null;
  const featureCenterPt = featuresMap?.has(feature.properties.id)
    ? featuresMap.get(feature.properties.id).centerPt
    : [0, 0];

  const closestFeatures = targetFeatures.filter((f) => {
    const fCenterPt = featuresMap?.has(f.properties.id)
      ? featuresMap.get(f.properties.id).centerPt
      : [0, 0];

    if (
      featureCenterPt[0] === fCenterPt[0] &&
      featureCenterPt[1] === fCenterPt[1]
    ) {
      return true;
    }
    return false;
  });

  const matchingFeatures = [];

  if (
    [GEOJSON_TYPES.MultiPoint, GEOJSON_TYPES.MultiPolygon].includes(
      feature?.geometry.type
    )
  ) {
    return [];
  } else {
    for (const f of closestFeatures) {
      if (f?.properties?.id !== feature?.properties?.id) {
        const fBbox = featuresMap?.has(f.properties.id)
          ? featuresMap.get(f.properties.id).bbox
          : null;

        const isMatchingParams =
          f?.geometry?.type === feature?.geometry?.type &&
          f?.properties?.className === feature?.properties?.className &&
          Math.round(f?.properties?.area || 0) ===
            Math.round(feature?.properties?.area || 0) &&
          Math.round(f?.properties?.perimeter || 0) ===
            Math.round(feature?.properties?.perimeter || 0);

        if (f?.geometry.type === GEOJSON_TYPES.Point && isMatchingParams) {
          const fCoordinates = f?.geometry?.coordinates;
          const featureCoordinates = feature?.geometry?.coordinates;

          if (
            Math.round(fCoordinates[0]) === Math.round(featureCoordinates[0]) &&
            Math.round(fCoordinates[1]) === Math.round(featureCoordinates[1])
          ) {
            matchingFeatures.push(f);
          }
        } else {
          if (fBbox && featureBbox) {
            const fBboxBounds = fBbox?.bbox;
            const fBboxWidth = Math.round(fBboxBounds[2] - fBboxBounds[0]);
            const fBboxHeight = Math.round(fBboxBounds[3] - fBboxBounds[1]);

            const featureBboxBounds = featureBbox?.bbox;
            const featureBboxWidth = Math.round(
              featureBboxBounds[2] - featureBboxBounds[0]
            );
            const featureBboxHeight = Math.round(
              featureBboxBounds[3] - featureBboxBounds[1]
            );

            if (
              isMatchingParams &&
              fBboxWidth === featureBboxWidth &&
              fBboxHeight === featureBboxHeight
            ) {
              matchingFeatures.push(f);
            }
          }
        }
      }
    }
  }

  return matchingFeatures;
}

export function cleanDedupedFeatures(features) {
  const featuresMap = new Map();
  features.forEach((f) => {
    const featureBbox = bboxPolygon(bbox(f));
    const centerPt = [
      Math.round((featureBbox?.bbox[0] + featureBbox?.bbox[2]) / 2),
      Math.round((featureBbox?.bbox[1] + featureBbox?.bbox[3]) / 2),
    ];

    featuresMap.set(f.properties.id, {
      feature: f,
      centerPt,
      bbox: featureBbox,
    });
  });

  const duplicateIds = [];
  const cleanedFeatures = [];
  const deleteIds = [];

  for (const f of features) {
    if (!duplicateIds.includes(f.properties.id)) {
      cleanedFeatures.push(f);
      duplicateIds.push(f.properties.id);

      const fMatches = findFeatureMatch(f, features, featuresMap);
      if (fMatches.length > 0) {
        const fMatchesIds = fMatches.map((f) => f.properties.id);
        duplicateIds.push(...fMatchesIds);
        deleteIds.push(...fMatchesIds);
      }
    }
  }

  return { deleteIds, cleanedFeatures };
}

export function updateViewFeaturesWithRotation(view) {
  const { cleanedFeatures } = cleanDedupedFeatures(view?.features || []);

  const rotation = view?.page?.metadata?.rotateRad || 0;
  const rotatedFeatures = rotateFeatures(view, cleanedFeatures, rotation);

  return cleanDuplicates(rotatedFeatures);
}

export function normalizeRotation(rotation) {
  let normalizedRotation = rotation % (2 * Math.PI);
  if (normalizedRotation < 0) {
    normalizedRotation += 2 * Math.PI;
  }
  return normalizedRotation;
}

export function fixViewRotations(view) {
  const pageRotation = view?.page?.metadata?.rotateRad || 0;
  const viewRotation = view?.metadata?.rotateRad || 0;

  const rotation = normalizeRotation(pageRotation - viewRotation);

  const rotatedFeatures = rotateFeatures(view, view?.features, rotation);

  return {
    ...view,
    features: rotatedFeatures,
    metadata: {
      ...view.metadata,
      rotateRad: pageRotation,
    },
  };
}

export function rotatePageFeatures(page, rotation, ignoreScaling = false) {
  const textFeatures = page?.geojson_text?.features || [];

  const scaledTextFeatures = ignoreScaling
    ? textFeatures
    : textFeatures
        ?.map((fe) => scaleUpFeature(fe, page))
        .filter(Boolean)
        .map((fe) => ({
          ...fe,
          properties: {
            ...fe.properties,
            name:
              fe?.properties?.name || (fe?.properties?.str || "")?.includes(" ")
                ? "para"
                : "text",
            className: DEFAULT_TEXT_FEATURES_ID,
          },
        }));

  const rotatedTextFeatures = rotateFeatures(
    page,
    scaledTextFeatures,
    rotation
  );

  return {
    ...page,
    textDocument: getDocumentType(textFeatures),
    geojson: {
      type: GEOJSON_TYPES.FeatureCollection,
      features: rotateFeatures(page, page?.geojson?.features || [], rotation),
    },
    geojson_text: {
      type: GEOJSON_TYPES.FeatureCollection,
      features: rotatedTextFeatures,
    },
    scaled_geojson_text: {
      type: GEOJSON_TYPES.FeatureCollection,
      features: rotatedTextFeatures,
    },
  };
}

export function rotateImageSearchFeatures(view, features) {
  const searchFeatures = features || [];

  const rotation = normalizeRotation(view?.page?.metadata?.rotateRad || 0);
  const rotatedSearchFeatures = rotateFeatures(view, searchFeatures, rotation);

  return rotatedSearchFeatures;
}

export function handleFractionInput(input) {
  if (isString(input) && input.includes("/")) {
    const [numerator, denominator] = input.split("/").map(Number);

    if (!isNaN(numerator) && !isNaN(denominator)) {
      return numerator / denominator;
    } else {
      return "";
    }
  } else if (!isNaN(Number(input))) {
    return input;
  } else {
    return "";
  }
}

export function getDocumentType(features = []) {
  return (
    features?.find((f) => f?.properties?.name === "document_type")?.properties
      ?.str === "text"
  );
}

export function getColorUsageLists(flattenCls) {
  const usedColorsList = Array.from(
    new Map(flattenCls.map((cl) => [cl.colorStyle.hex, cl.colorStyle])).values()
  );

  const usedColorsHexList = usedColorsList?.map((color) => color?.hex);

  const unusedColor = CLS_COLORS.find(
    (cl) => !usedColorsHexList.includes(cl?.hex)
  );

  const unusedColorsList = CLS_COLORS.filter(
    (cl) => !usedColorsHexList.includes(cl?.hex)
  );

  return { usedColorsList, unusedColor, unusedColorsList };
}
