import React from "react";

import Icon from "../components/Icon";
import { DEFAULT_ML_PLUMBING_COUNTS, MODES_IDS } from "./editor";
import { PIPELINE_ICONS_IDS } from "../components/Icon/pipeline_icons";

export const PIPE_VECTOR_TYPE = "pipe_vector";

export const PIPELINE_WIDTHS = [0.5, 0.75, 1, 1.5, 2, 3, 4];
export const PIPELINE_LENGTHS = new Array(24)
  .fill(0)
  .map((_, i) => (i + 1) * 6);

export const PIPELINE_WIDTHS_MAP: any = {
  0.5: `1/2" PEX Line`,
  0.75: `3/4" PEX Line`,
  1: `1" PEX Line`,
  1.5: `1,1/2" PVC Pipe`,
  2: `2" PVC Pipe`,
  3: `3" PVC Pipe`,
  4: `4" PVC Pipe`,
};

export const PIPELINE_WIDTHS_LABELS: any = {
  0.5: `1/2"`,
  0.75: `3/4"`,
  1: `1"`,
  1.5: `1,1/2"`,
  2: `2"`,
  3: `3"`,
  4: `4"`,
};

export const PIPELINE_TYPES: any = {
  pvc2: "pvc2",
  pvc3: "pvc3",
  pvc4: "pvc4",
  pvc5: "pvc5",
  pvc6: "pvc6",
};

export const PIPELINE_TYPES_WIDTHS_MAP: any = {
  [PIPELINE_TYPES.pvc2]: 1,
  [PIPELINE_TYPES.pvc3]: 1.5,
  [PIPELINE_TYPES.pvc4]: 2,
  [PIPELINE_TYPES.pvc5]: 3,
  [PIPELINE_TYPES.pvc6]: 4,
};

export const PIPELINE_MODE_TOOLS = {
  EXIT_DRAIN: "Exit Drain",
  PIPELINE: "Draw Pipeline",
  DRAIN: "Drains",
  EDIT: "Edit",
};

export const PIPELINE_TOOL = [
  {
    id: 1,
    toolIds: [PIPELINE_MODE_TOOLS.PIPELINE],
    width: 120,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS.PIPELINE,
    value: PIPELINE_MODE_TOOLS.PIPELINE,
    icon: () => (
      <Icon
        id={PIPELINE_ICONS_IDS.pipeline}
        width={28}
        height={28}
        style={{
          transform: "rotate(90deg)",
        }}
      />
    ),
  },
];

export const DRAIN_TOOLS = DEFAULT_ML_PLUMBING_COUNTS.map((el, index) => ({
  id: index + 2,
  toolIds: DEFAULT_ML_PLUMBING_COUNTS,
  width: 60,
  showIcon: false,
  title: PIPELINE_MODE_TOOLS.DRAIN,
  value: el,
  icon: () => <Icon id={PIPELINE_ICONS_IDS.drain} width={28} height={28} />,
}));

export const EDIT_TOOL = [
  {
    id: 11,
    toolIds: [PIPELINE_MODE_TOOLS.EDIT],
    width: 40,
    showIcon: false,
    title: PIPELINE_MODE_TOOLS.EDIT,
    value: PIPELINE_MODE_TOOLS.EDIT,
    icon: () => <Icon id={PIPELINE_ICONS_IDS.edit} width={20} height={20} />,
  },
];

export const ENTRY_TOOL = [
  {
    id: 12,
    toolIds: [PIPELINE_MODE_TOOLS.EXIT_DRAIN],
    width: 70,
    showIcon: true,
    title: PIPELINE_MODE_TOOLS.EXIT_DRAIN,
    value: PIPELINE_MODE_TOOLS.EXIT_DRAIN,
    icon: () => <Icon id={MODES_IDS.markup_arrow} width={20} height={20} />,
  },
];

export const ALL_PIPELINE_TOOLS = [
  EDIT_TOOL,
  PIPELINE_TOOL,
  DRAIN_TOOLS,
  ENTRY_TOOL,
];
