import { Feature, MultiPoint, Point, Position } from "geojson";

import turfBearing from "@turf/bearing";
import turfDistance from "@turf/distance";
import turfCentroid from "@turf/centroid";
import { getCoord } from "@turf/invariant";
import turfTransformScale from "@turf/transform-scale";
import { point, featureCollection, lineString } from "@turf/helpers";

import {
  degToRad,
  getImageBoundingBox,
  updateGeometryWithPadding,
} from "../../utils/coordinates";
import { GEOJSON_TYPES } from "../../consts/editor";
import { divide } from "../../utils/functional/math";
import GeoJsonEditMode from "../base/GeojsonEditMode";
import { getPickedEditHandle } from "../../utils/modes";
import { DEFAULT_PADDING } from "../../consts/coordinates";
import polygonToLine from "../../utils/turf/polygon-to-line";

export class ImageTransformMode extends GeoJsonEditMode {
  private _selectedEditHandle: null;
  private _cornerGuidePoints: Feature<Point>[];
  private _isScaling: boolean;
  private _isRotating: boolean;
  private _isTranslating: boolean;
  private _geometryBeingScaled: Feature;
  private _comparisonTransformsBefore: any;

  constructor() {
    super();

    this._selectedEditHandle = null;
    this._cornerGuidePoints = null;

    this._isScaling = false;
    this._isRotating = false;
    this._isTranslating = false;
    this._comparisonTransformsBefore = null;
  }

  getGuides = (props: any) => {
    const { modeConfig } = props;

    if (modeConfig.targetImage && modeConfig.comparisonTransforms) {
      const imageBBox = getImageBoundingBox(
        {
          width: modeConfig.targetImage.width,
          height: modeConfig.targetImage.height,
          tx: modeConfig.comparisonTransforms.translateX,
          ty: modeConfig.comparisonTransforms.translateY,
          scale: modeConfig.comparisonTransforms.scale,
          rotate: modeConfig.comparisonTransforms.rotateRad,
        },
        true
      );

      const boundingBox: any = {
        type: GEOJSON_TYPES.Feature,
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates: [
            [
              [imageBBox[0], imageBBox[1]],
              [imageBBox[2], imageBBox[1]],
              [imageBBox[2], imageBBox[3]],
              [imageBBox[0], imageBBox[3]],
              [imageBBox[0], imageBBox[1]],
            ],
          ],
        },
        bbox: imageBBox,
        properties: {
          mode: "scale",
        },
      };

      if (this._isRotating) {
        return featureCollection([turfCentroid(boundingBox)]);
      }

      const cornerGuidePoints: Feature<Point>[] = [];

      boundingBox.geometry.coordinates[0].forEach(
        (coord: Position, coordIndex: number) => {
          if (coordIndex < 4) {
            const cornerPoint = point(coord, {
              guideType: "editHandle",
              editHandleType: "scale",
              positionIndexes: [coordIndex],
            });
            cornerGuidePoints.push(cornerPoint);
          }
        }
      );

      // inspired from https://github.com/uber/nebula.gl/blob/master/modules/edit-modes/src/lib/rotate-mode.ts
      const topEdgeMidpointCoords = [
        (imageBBox[2] + imageBBox[0]) / 2,
        imageBBox[1],
      ];
      const longestEdgeLength = Math.round(
        Math.abs(imageBBox[2] - imageBBox[0])
      );

      const rotateHandleCoords = topEdgeMidpointCoords && [
        topEdgeMidpointCoords[0],
        topEdgeMidpointCoords[1] - longestEdgeLength / 10,
      ];

      const lineFromEnvelopeToRotateHandle = lineString([
        topEdgeMidpointCoords,
        rotateHandleCoords,
      ]);

      const rotateHandle = point(rotateHandleCoords, {
        guideType: "editHandle",
        editHandleType: "rotate",
      });

      if (cornerGuidePoints.length !== 4) return;
      this._cornerGuidePoints = cornerGuidePoints;

      return featureCollection([
        polygonToLine(boundingBox) as Feature,
        ...this._cornerGuidePoints,
        rotateHandle,
        lineFromEnvelopeToRotateHandle,
      ]);
    }
  };

  handleStartDragging = (event: any, props: any) => {
    const { modeConfig } = props;

    if (modeConfig.forcePanMode) return;

    const editHandle = getPickedEditHandle(event.picks);
    const imagePick =
      event.picks.length > 0
        ? event.picks[0].layer.id === modeConfig.targetImageID || null
        : null;

    if (modeConfig.targetImage && modeConfig.comparisonTransforms) {
      this._comparisonTransformsBefore = modeConfig.comparisonTransforms;
      this._selectedEditHandle =
        editHandle && editHandle.properties.editHandleType === "scale"
          ? editHandle
          : null;

      const imageBBox = getImageBoundingBox({
        width: modeConfig.targetImage.width,
        height: modeConfig.targetImage.height,
        tx: modeConfig.comparisonTransforms.translateX,
        ty: modeConfig.comparisonTransforms.translateY,
        scale: modeConfig.comparisonTransforms.scale,
        rotate: modeConfig.comparisonTransforms.rotateRad,
      });

      this._geometryBeingScaled = {
        type: GEOJSON_TYPES.Feature,
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates: [
            [
              [imageBBox[0], imageBBox[1]],
              [imageBBox[2], imageBBox[1]],
              [imageBBox[2], imageBBox[3]],
              [imageBBox[0], imageBBox[3]],
              [imageBBox[0], imageBBox[1]],
            ],
          ],
        },
        properties: {},
      };

      if (imagePick) {
        this._isScaling = false;
        this._isRotating = false;
        this._isTranslating = true;
      } else if (
        editHandle &&
        editHandle?.properties?.editHandleType === "scale"
      ) {
        this._isScaling = true;
        this._isRotating = false;
        this._isTranslating = false;
      } else if (
        editHandle &&
        editHandle?.properties?.editHandleType === "rotate"
      ) {
        this._isScaling = false;
        this._isRotating = true;
        this._isTranslating = false;
      }
    }
  };

  handleDragging = (event: any, props: any) => {
    const { modeConfig } = props;
    const imagePick =
      event.picks.length > 0
        ? event.picks[0].layer.id === modeConfig.targetImageID || null
        : null;

    if (this._isScaling || this._isRotating || this._isTranslating) {
      event.cancelPan();
    }

    if (modeConfig.targetImage && modeConfig.comparisonTransforms) {
      if (
        imagePick &&
        this._isTranslating &&
        this._comparisonTransformsBefore
      ) {
        const newTranslateX = Math.round(
          this._comparisonTransformsBefore.translateX +
            event.mapCoords[0] -
            event.pointerDownMapCoords[0]
        );
        const newTranslateY = Math.round(
          this._comparisonTransformsBefore.translateY +
            event.mapCoords[1] -
            event.pointerDownMapCoords[1]
        );

        this.applyActions(props, true, {
          editType: "translateImage",
          updatedData: {
            translateX: newTranslateX,
            translateY: newTranslateY,
          },
        });
      }

      if (
        this._isScaling &&
        this._geometryBeingScaled &&
        this._comparisonTransformsBefore
      ) {
        const [updatedGeometry, scaleFactor] = this.getScaleAction(
          event.pointerDownMapCoords,
          event.mapCoords
        );

        if (updatedGeometry) {
          const [newTranslateX, newTranslateY] =
            updatedGeometry.geometry.coordinates[0][0];

          this.applyActions(props, true, {
            updatedData: {
              scaleFactor: this._comparisonTransformsBefore.scale * scaleFactor,
              translateX: newTranslateX,
              translateY: newTranslateY,
            },
            editType: "scaleImage",
          });
        }
      }

      if (
        this._isRotating &&
        this._geometryBeingScaled &&
        this._comparisonTransformsBefore
      ) {
        const rotationRad = this.getRotateAction(
          event.pointerDownMapCoords,
          event.mapCoords
        );

        this.applyActions(props, true, {
          updatedData: {
            rotationRad:
              this._comparisonTransformsBefore.rotateRad - rotationRad,
          },
          editType: "rotateImage",
        });
      }
    }
  };

  handleStopDragging = (event: any, props: any) => {
    if (this._isScaling || this._isTranslating || this._isRotating) {
      this._isScaling = false;
      this._isRotating = false;
      this._isTranslating = false;
      this._geometryBeingScaled = null;
      this._comparisonTransformsBefore = null;

      this.applyActions(props, true, {
        editType: "finishImageTransform",
      });
    }
  };

  handlePointerMove = (event: any, props: any) => {
    if (this._isTranslating) {
      props.onUpdateCursor("move");
    } else if (this._isScaling || this._isRotating) {
      props.onUpdateCursor("crosshair");
    } else {
      props.onUpdateCursor(null);
    }
  };

  private _getOppositeScaleHandle = (selectedHandle: any) => {
    const selectedHandleIndex =
      selectedHandle &&
      selectedHandle.properties &&
      Array.isArray(selectedHandle.properties.positionIndexes) &&
      selectedHandle.properties.positionIndexes[0];

    if (typeof selectedHandleIndex !== "number") {
      return null;
    }
    const guidePointCount = this._cornerGuidePoints.length;
    const oppositeIndex =
      (selectedHandleIndex + guidePointCount / 2) % guidePointCount;
    return this._cornerGuidePoints.find((p) => {
      if (!Array.isArray(p.properties.positionIndexes)) {
        return false;
      }
      return p.properties.positionIndexes[0] === oppositeIndex;
    });
  };

  getScaleAction = (startDragPoint: Position, currentPoint: Position) => {
    if (!this._selectedEditHandle) {
      return null;
    }

    const oppositeHandle = this._getOppositeScaleHandle(
      this._selectedEditHandle
    );

    let origin = getCoord(oppositeHandle);
    const scaleFactor = getScaleFactor(origin, startDragPoint, currentPoint);

    const padding = DEFAULT_PADDING;
    origin = origin.map(divide(padding));

    const scaledDown = updateGeometryWithPadding(
      padding,
      this._geometryBeingScaled
    );

    const scaledFeature = turfTransformScale(scaledDown, scaleFactor, {
      origin,
    });

    const scaledUp = updateGeometryWithPadding(1 / padding, scaledFeature);

    return [scaledUp, scaleFactor];
  };

  getRotateAction = (startDragPoint: Position, currentPoint: Position) => {
    if (!this._geometryBeingScaled) {
      return null;
    }

    const centroid = turfCentroid(
      this._geometryBeingScaled as Feature<MultiPoint>
    );

    return getRotationAngle(
      centroid.geometry.coordinates,
      startDragPoint,
      currentPoint
    );
  };
}

function getScaleFactor(
  centroid: Position,
  startDragPoint: Position,
  currentPoint: Position
) {
  centroid = centroid.map(divide(DEFAULT_PADDING));
  startDragPoint = startDragPoint.map(divide(DEFAULT_PADDING));
  currentPoint = currentPoint.map(divide(DEFAULT_PADDING));

  const startDistance = turfDistance(centroid, startDragPoint);
  const endDistance = turfDistance(centroid, currentPoint);
  return endDistance / startDistance;
}

function getRotationAngle(
  centroid: Position,
  startDragPoint: Position,
  currentPoint: Position
) {
  centroid = centroid.map(divide(DEFAULT_PADDING));
  startDragPoint = startDragPoint.map(divide(DEFAULT_PADDING));
  currentPoint = currentPoint.map(divide(DEFAULT_PADDING));

  const bearing1 = turfBearing(centroid, startDragPoint);
  const bearing2 = turfBearing(centroid, currentPoint);
  const deg = bearing2 - bearing1;
  return degToRad(deg);
}
