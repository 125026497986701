import React from "react";

import * as P from "./props";
import * as S from "./styles";

import Tooltip from "../TooltipBase";
import { CSS } from "../../../consts/style";

const Spinner = ({
  color = CSS.GRAY6,
  size = "20px",
  tooltipContent = "",
  tooltipProps = {},
}: P.SpinnerProps) => {
  return (
    <Tooltip content={tooltipContent} {...tooltipProps}>
      <S.SpinnerWrapper color={color} size={size} />
    </Tooltip>
  );
};

Spinner.displayName = "Spinner";

export default Spinner;
