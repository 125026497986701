import { Feature } from "geojson";
import bbox from "@turf/bbox";

import IView from "../types/IView";
import { distance2d } from "./modes";
import { getIou } from "../modes/mutate/utils";
import ImmutableLayersData from "../modes/base/ImmutableLayersData";
import { GEOJSON_TYPES, DEFAULT_FOOTPRINT_ID } from "../consts/editor";

export function handleGroupSelectedData(
  data: Feature[],
  selected: string[],
  view: IView,
  clMap: any
) {
  let newFeatures = new ImmutableLayersData(data)
    .filterSelected(selected)
    .getLayers();

  let filteredPolygons: any = new ImmutableLayersData(
    newFeatures
  ).filterGeometryType([GEOJSON_TYPES.Polygon, GEOJSON_TYPES.MultiPolygon]);

  let filteredPoints: any = new ImmutableLayersData(
    newFeatures
  ).filterGeometryType([GEOJSON_TYPES.Point, GEOJSON_TYPES.MultiPoint]);

  let filteredLines: any = new ImmutableLayersData(
    newFeatures
  ).filterGeometryType([
    GEOJSON_TYPES.LineString,
    GEOJSON_TYPES.MultiLineString,
  ]);

  const deleteFeatures = [];
  const addFeatures = [];

  if (filteredLines.getLayers().length > 1) {
    deleteFeatures.push(...filteredLines.getLayers());
    filteredLines = filteredLines.makeIntoMultiLine(view, clMap).getLayers();
    addFeatures.push(...filteredLines);
  } else {
    filteredLines = [];
  }

  if (filteredPolygons.getLayers().length > 1) {
    deleteFeatures.push(...filteredPolygons.getLayers());
    filteredPolygons = filteredPolygons
      .makeIntoMultiPolygons(view, clMap)
      .getLayers();
    addFeatures.push(...filteredPolygons);
  } else {
    filteredPolygons = [];
  }

  if (filteredPoints.getLayers().length > 1) {
    deleteFeatures.push(...filteredPoints.getLayers());
    filteredPoints = filteredPoints
      .makeIntoMultiPoints(view, clMap)
      .getLayers();
    addFeatures.push(...filteredPoints);
  } else {
    filteredPoints = [];
  }

  return {
    deleteFeatures,
    addFeatures,
  };
}

export function handleUnGroupSelectedData(
  data: Feature[],
  selected: string[],
  view: IView,
  clMap: any
) {
  let newFeatures = new ImmutableLayersData(data)
    .filterSelected(selected)
    .getLayers();

  let filteredMultiPolygons: any = new ImmutableLayersData(
    newFeatures
  ).filterGeometryType([GEOJSON_TYPES.MultiPolygon]);

  let filteredMultiPoints: any = new ImmutableLayersData(
    newFeatures
  ).filterGeometryType([GEOJSON_TYPES.MultiPoint]);

  let filteredMultiLines: any = new ImmutableLayersData(
    newFeatures
  ).filterGeometryType([GEOJSON_TYPES.MultiLineString]);

  const deleteFeatures = [];
  const addFeatures = [];

  if (filteredMultiLines.getLayers().length > 0) {
    deleteFeatures.push(...filteredMultiLines.getLayers());
    filteredMultiLines = filteredMultiLines
      .makeIntoLines(view, clMap)
      .getLayers();
    addFeatures.push(...filteredMultiLines);
  } else {
    filteredMultiLines = [];
  }

  if (filteredMultiPolygons.getLayers().length > 0) {
    deleteFeatures.push(...filteredMultiPolygons.getLayers());
    filteredMultiPolygons = filteredMultiPolygons
      .makeIntoPolygons(view, clMap)
      .getLayers();
    addFeatures.push(...filteredMultiPolygons);
  } else {
    filteredMultiPolygons = [];
  }

  if (filteredMultiPoints.getLayers().length > 0) {
    deleteFeatures.push(...filteredMultiPoints.getLayers());
    filteredMultiPoints = filteredMultiPoints
      .makeIntoPoints(view, clMap)
      .getLayers();
    addFeatures.push(...filteredMultiPoints);
  } else {
    filteredMultiPoints = [];
  }

  return {
    deleteFeatures,
    addFeatures,
  };
}

export function getSuggestion(feature: any, ml_features: any[]) {
  if (!ml_features) {
    return feature;
  }

  const type = feature.geometry.type;
  const { types = [], id = "" } = feature.properties;

  const isLineString =
    type === GEOJSON_TYPES.LineString || type === GEOJSON_TYPES.MultiLineString;
  const isPolygon =
    type === GEOJSON_TYPES.Polygon || type === GEOJSON_TYPES.MultiPolygon;
  const isNotMarkupOrFootprint =
    !types.includes(GEOJSON_TYPES.markup) &&
    !types.includes(DEFAULT_FOOTPRINT_ID);

  if (isLineString && isNotMarkupOrFootprint) {
    if (feature?.properties?.ml_classification?.length) {
      return {
        ...feature,
        properties: {
          ...feature.properties,
          suggestion: "wall_" + feature.properties.ml_classification[0],
        },
      };
    } else {
      return feature;
    }
  }

  if (isPolygon && isNotMarkupOrFootprint) {
    const featureFirstCoord = feature.geometry.coordinates[0][0];
    const featureBbox = bbox(feature);
    const featureWidth = featureBbox[2] - featureBbox[0];

    if (feature?.properties?.ml_classification?.length) {
      return {
        ...feature,
        properties: {
          ...feature.properties,
          suggestion: "area_" + feature.properties.ml_classification[0],
        },
      };
    } else {
      let highestIOU = 0;
      let suggestionFeature: any = null;

      for (const ml_fe of ml_features) {
        if (ml_fe.geometry.type === GEOJSON_TYPES.Polygon) {
          const mlFEFirstCoord = ml_fe.geometry.coordinates[0][0];
          const distance = distance2d(
            featureFirstCoord[0],
            featureFirstCoord[1],
            mlFEFirstCoord[0],
            mlFEFirstCoord[1]
          );

          if (distance < 2 * featureWidth) {
            const iou = getIou(feature, ml_fe);
            if (iou > highestIOU && iou > 0.2) {
              highestIOU = iou;
              suggestionFeature = ml_fe;
            }
          }
        }
      }

      if (
        suggestionFeature &&
        suggestionFeature?.properties?.ml_classification
      ) {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            suggestion:
              "area_" + suggestionFeature.properties.ml_classification[0],
          },
        };
      }
    }
  }

  return feature;
}

export function cleanDuplicates(data: Feature[]): Feature[] {
  const uniquesIds = new Set(data.map((fe) => fe.properties.id));

  const cleanedData: Feature[] = [];

  data.forEach((feature) => {
    if (uniquesIds.has(feature.properties.id)) {
      cleanedData.push(feature);
      uniquesIds.delete(feature.properties.id);
    }
  });

  return cleanedData;
}
