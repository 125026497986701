import { FeatureCollection } from "geojson";

import GeoJsonEditMode from "../base/GeojsonEditMode";

import {
  GEOJSON_TYPES,
  PIPELINE_PREFIX,
  DEFAULT_PIPLELINES_CLASSiFICATIONS_MAP,
} from "../../consts/editor";

import { CANVAS } from "../../consts/events";
import { RGB_COLORS } from "../../consts/style";
import { KEYS } from "../../consts/keysAndMouse";
import { SNAP_ANGLES_S } from "../../consts/coordinates";
import {
  PIPELINE_WIDTHS,
  PIPE_VECTOR_TYPE,
  PIPELINE_WIDTHS_MAP,
} from "../../consts/pipeline";

import {
  makeFeature,
  getFeatureMeasures,
} from "../../modes/base/ImmutableLayersData";
import { translateFeatures } from "../../modes/edit/editUtils";

import { last } from "../../utils/functional/list";
import { getTwoClickPolygon } from "../../utils/geometry";
import booleanIntersects from "../../utils/turf/boolean-intersects";

import {
  distance2d,
  getPickedPipes,
  getEditHandlesForFeature,
} from "../../utils/modes";
import {
  snapToDegAbs,
  makeArrowFromLine,
  getDistanceTooltip,
  createTentativeFeature,
  getSnappedFeatureToLengths,
  updatePipeLineFeatureCoordinates,
} from "../../utils/coordinates";
import {
  generateJoints,
  getNewDrainLine,
  pipelineToPolygon,
  isFeaturePipeline,
  generate3dPipeline,
  roundFeatureCoords,
  getUpdatedPipelines,
  updatePipelineHeight,
  lineFeatureToSegments,
  getPipelineModeDetails,
  getPipelinesNearestPoint,
  convertPipelineApiToNormalPipelines,
} from "../../utils/pipeline";

export class DrawPipelineMode extends GeoJsonEditMode {
  mainFeature: any;
  _arrowFeature: any;
  _cache: any = new Map();
  _isFrozen: boolean = false;
  _isInitialized: boolean = false;
  _isHeightMode: boolean = false;

  _hidden: any = new Set();
  _selection: any = new Set();

  _snapTargets: any = null;

  _isDragging: boolean = false;
  _draggingFeatures: any = [];
  _draggingStartCoords: any = [];
  _featuresBeforeDragging: any = [];

  _joints: any = [];
  _nearestPoint: any;

  _pipelines: any = [];
  _pipelinesApi: any = [];
  _pipelineMesh: any = null;
  _hoveredFeature: any = null;
  _pipelineApiMesh: any = null;
  _mainFeatureMesh: any = null;

  _pipelineWidth: number = PIPELINE_WIDTHS[0];

  constructor() {
    super();
  }

  _resetState = () => {
    this.resetClickSequence();

    this.mainFeature = null;
    this._mainFeatureMesh = null;

    this._isHeightMode = false;
  };

  dispatchEvent = () => {
    const event = new CustomEvent(CANVAS.CANVAS_CUSTOM_UI_INPUT, {
      detail: {
        inputs: [],
      },
    });

    window.dispatchEvent(event);
  };

  get3dGuides = (props: any) => {
    const { is3dMode } = props.modeConfig;

    if (is3dMode) {
      return [
        this._pipelineMesh,
        this._pipelineApiMesh,
        this._mainFeatureMesh,
      ].filter(Boolean);
    }

    return [];
  };

  getGuides = (props: any) => {
    const { lastPointerMoveEvent, modeConfig } = props;
    const {
      hidden,
      geoJson,
      selected,
      colorMap,
      isLengthSnapping,
      activeClassification,
      referenceInchesPerPixels,
    } = modeConfig;

    if (!this._isInitialized) {
      this._isInitialized = true;
      const initialPipelines = geoJson
        .filter(isFeaturePipeline)
        .map((f: any) => ({ ...f, properties: { ...f.properties } }));

      this._pipelines = initialPipelines;

      if (this._pipelines.length > 0) {
        this.generateMesh(referenceInchesPerPixels);
      }

      this._selection = new Set(selected);
      this._hidden = new Set(hidden);
    }

    const modeDetails = getPipelineModeDetails(modeConfig?.pipelineMode);
    const mapCoords = lastPointerMoveEvent?.mapCoords || [0, 0];

    const mergedPipelines = (this._pipelines || [])
      .concat(this._pipelinesApi || [])
      .filter((p: any) => !this._hidden.has(p.properties.id));

    if (!modeDetails.isPipelineMode && this._clickSequence.length > 0) {
      this._resetState();
    }

    const guides: FeatureCollection = {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [],
    };

    if (this._arrowFeature) {
      if (!this._arrowFeature.properties._finalized) {
        const newArrowCoordiante = snapToDegAbs(
          this._arrowFeature.geometry.coordinates[0],
          [mapCoords],
          SNAP_ANGLES_S
        );
        this._arrowFeature.geometry.coordinates[1] = newArrowCoordiante;
        const arrowFeature = makeArrowFromLine(this._arrowFeature, 45);
        guides.features.push(arrowFeature);
      } else {
        guides.features.push(this._arrowFeature);
      }
    }

    if (
      mergedPipelines?.length > 0 &&
      this._clickSequence.length === 0 &&
      (modeDetails.isPipelineMode ||
        modeDetails.isDrainMode ||
        (modeDetails.isEditMode && this._isDragging))
    ) {
      try {
        const filteredLines =
          modeDetails.isEditMode && this._isDragging
            ? mergedPipelines.filter(
                (p: any) =>
                  !this._selection.has(p.properties.id) &&
                  !this._hidden.has(p.properties.id)
              )
            : mergedPipelines;

        const nearest = getPipelinesNearestPoint(filteredLines, mapCoords);

        if (nearest) {
          this._nearestPoint = nearest;
          guides.features.push(nearest);
        } else {
          this._nearestPoint = null;
        }
      } catch (error) {
        console.error("error", error);
        this._nearestPoint = null;
      }
    } else if (this._nearestPoint) {
      this._nearestPoint = null;
    }

    if (modeDetails.isPipelineMode) {
      const guideType = props?.modeConfig?.guideType || "tentative";

      if (this._clickSequence.length > 0) {
        const isShiftKey = lastPointerMoveEvent?.sourceEvent?.shiftKey;
        const [mainFeature, ..._features] = createTentativeFeature(
          props,
          this,
          guideType,
          false,
          false,
          {
            angles: SNAP_ANGLES_S,
          }
        );

        const mainFeatureAsLine = {
          ...mainFeature,
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates:
              mainFeature.geometry.type === GEOJSON_TYPES.MultiLineString
                ? mainFeature.geometry.coordinates[0]
                : mainFeature.geometry.coordinates,
          },
        };
        const mainLine =
          !isShiftKey && isLengthSnapping
            ? getSnappedFeatureToLengths(
                mainFeatureAsLine,
                referenceInchesPerPixels
              )
            : mainFeatureAsLine;

        this.mainFeature = mainLine;
        guides.features = guides.features.concat(
          getEditHandlesForFeature(this.mainFeature, -1)
        );

        if (this.mainFeature) {
          this.mainFeature = {
            ...this.mainFeature,
            properties: {
              ...this.mainFeature.properties,
              className:
                activeClassification?.id ??
                PIPELINE_WIDTHS_MAP[this._pipelineWidth],
              pipelineWidth: this._pipelineWidth,
            },
          };

          this.generateMainFeatureMesh(referenceInchesPerPixels);

          const lines = lineFeatureToSegments(
            this.mainFeature,
            true,
            this.mainFeature.properties
          )
            .map(
              (f: any) =>
                pipelineToPolygon(f, colorMap, referenceInchesPerPixels)
                  .properties.linePolygon
            )
            .filter(Boolean);
          if (lines?.length > 0) {
            guides.features = guides.features.concat(lines);
          }
        }
      }
    }

    if (mergedPipelines?.length > 0) {
      guides.features = guides.features.concat(
        mergedPipelines.map((p: any) => ({
          ...p.properties.linePolygon,
          properties: {
            ...p.properties.linePolygon.properties,
            color:
              this._selection.has(p.properties.id) ||
              this._hoveredFeature?.properties.id === p.properties.id
                ? RGB_COLORS.BLUE
                : p.properties.linePolygon.properties.color,
            borderColor:
              this._selection.has(p.properties.id) ||
              this._hoveredFeature?.properties.id === p.properties.id
                ? RGB_COLORS.BLUE
                : p.properties.linePolygon.properties.borderColor,
          },
        }))
      );
    }

    if (modeDetails.isEditMode) {
      mergedPipelines.forEach((p: any) => {
        if (this._selection.has(p.properties.id)) {
          guides.features = guides.features.concat(
            getEditHandlesForFeature(
              p,
              -1,
              "existing",
              false,
              p.properties.id
            ).map((handle: any) => ({
              ...handle,
              properties: {
                ...handle.properties,
                editHandleType:
                  p.properties.id === this?._hoveredFeature?.properties?.id &&
                  this._hoveredFeature?.properties?.positionIndexes?.length &&
                  this._hoveredFeature?.properties?.positionIndexes[0] ===
                    handle.properties.positionIndexes[0]
                    ? "magic_handle"
                    : handle.properties.editHandleType,
              },
            }))
          );
        }
      });

      if (this._draggingStartCoords.length > 0) {
        const boxFeature = getTwoClickPolygon(
          this._draggingStartCoords,
          mapCoords
        );
        guides.features.push(boxFeature);
      }
    }

    guides.features = guides.features.concat(this._joints);

    return guides;
  };

  getTooltips = (props: any) => {
    const { colorMap, pipelineMode } = props.modeConfig;
    const tooltips: any = [];
    const modeDetails = getPipelineModeDetails(pipelineMode);

    let tooltipFeature;
    if (
      modeDetails.isEditMode &&
      this._draggingFeatures.length > 0 &&
      this._draggingFeatures[0].properties?.editHandleType
    ) {
      const parentFeature = this._pipelines.find(
        (p: any) => p.properties.id === this._draggingFeatures[0].properties.id
      );
      if (parentFeature) {
        tooltipFeature = parentFeature;
      }
    } else if (this._hoveredFeature && modeDetails.isEditMode) {
      tooltipFeature = this._hoveredFeature;
    } else if (modeDetails.isPipelineMode && this.mainFeature) {
      tooltipFeature = this.mainFeature;
    }

    if (tooltipFeature) {
      const distanceTooltips = getDistanceTooltip(
        props,
        tooltipFeature,
        this,
        colorMap,
        true
      );
      tooltips.push(...distanceTooltips);
    }

    return tooltips;
  };

  handleAddFeature = (event: any, props: any) => {
    const { colorMap, referenceInchesPerPixels } = props.modeConfig;

    const segments = lineFeatureToSegments(
      this.mainFeature,
      true,
      this.mainFeature.properties
    )
      .map((f: any) => {
        const pipelineFeature = pipelineToPolygon(
          {
            ...f,
            properties: {
              ...f.properties,
              pipelineWidth: this._pipelineWidth,
            },
          },
          colorMap,
          referenceInchesPerPixels
        );

        return pipelineFeature;
      })
      .filter(Boolean)
      .map(roundFeatureCoords);

    const addFeatures = [];

    for (const pipe of segments) {
      addFeatures.push(
        makeFeature({
          geometry: pipe.geometry,
          id: pipe.properties.id,
          view: props.modeConfig.view,
          type: PIPELINE_PREFIX,
          className: pipe.properties.className,
          featureAccessKey: props.featureAccessKey,
          clMap: {
            [pipe.properties.className || ""]: pipe.properties.className,
          },
          featureProperties: {
            ...pipe.properties,
            isPipeline: true,
          },
        })
      );
    }

    this._pipelines.push(...addFeatures);

    this.addFeatures(addFeatures);
    this.applyActions(props, true, {
      isPipeline: true,
      recalculateClassifications: true,
    });

    this._resetState();

    if (this._pipelines.length > 0) {
      this.generateMesh(referenceInchesPerPixels);
      this._joints = generateJoints(
        this._pipelines,
        this._cache,
        referenceInchesPerPixels
      );
    }
  };

  handleUpdatePipelineFomAPI = async (props: any) => {
    const vectorFeatures = [...props.data.features];
    const { geoJson, handleProccessPipeline } = props.modeConfig;

    if (this._arrowFeature) {
      vectorFeatures.push(this._arrowFeature);
    }

    try {
      const pipelineFeatures = await handleProccessPipeline(
        vectorFeatures,
        geoJson,
        this._pipelines
      );

      if (pipelineFeatures?.length > 0) {
        this._pipelinesApi = pipelineFeatures.map(roundFeatureCoords);

        this._pipelineApiMesh = await generate3dPipeline(
          this._pipelinesApi,
          0.25,
          0
        );
      } else {
        this._pipelinesApi = [];
        this._pipelineApiMesh = null;
      }
    } catch (error) {
      this._pipelinesApi = [];
      this._pipelineApiMesh = null;
      console.error("error", error);
    }
  };

  generateMesh = async (referenceInchesPerPixels: number) => {
    this._pipelineMesh = await generate3dPipeline(
      this._pipelines,
      referenceInchesPerPixels,
      0
    );
  };

  generateMainFeatureMesh = async (referenceInchesPerPixels: number) => {
    if (this.mainFeature) {
      const mainFeatureLines = lineFeatureToSegments(
        this.mainFeature,
        true,
        this.mainFeature.properties
      );

      this._mainFeatureMesh = await generate3dPipeline(
        mainFeatureLines,
        referenceInchesPerPixels,
        0
      );
    }
  };

  handleDblClick = (event: any, props: any) => {
    const { view, referenceInchesPerPixels } = props.modeConfig;

    const pickedFeature = getPickedPipes(event.picks)[0] || null;
    const apiPipeline =
      pickedFeature &&
      this._pipelinesApi.find(
        (p: any) => p?.properties?.id === pickedFeature?.properties?.id
      );

    if (apiPipeline) {
      const convertedPipes = convertPipelineApiToNormalPipelines(
        this._pipelinesApi,
        referenceInchesPerPixels
      ).map(roundFeatureCoords);

      this._pipelines = this._pipelines.concat(convertedPipes);

      this._pipelinesApi = [];
      this._pipelineApiMesh = null;
      this._arrowFeature = null;

      this.generateMesh(referenceInchesPerPixels);

      const addFeatures = [];
      for (const pipe of convertedPipes) {
        addFeatures.push(
          makeFeature({
            geometry: pipe.geometry,
            id: pipe.properties.id,
            view,
            type: PIPELINE_PREFIX,
            className: pipe.properties.className,
            featureAccessKey: props.featureAccessKey,
            clMap: {
              [pipe.properties.className || ""]: pipe.properties.className,
            },
            featureProperties: {
              ...pipe.properties,
              isPipeline: true,
            },
          })
        );
      }

      this.addFeatures(addFeatures);
      this.applyActions(props, true, {
        isPipeline: true,
        recalculateClassifications: true,
      });
    }
  };

  handleClick = (event: any, props: any) => {
    const {
      view,
      modeId,
      colorMap,
      selected,
      setSelected,
      pipelineMode,
      pipelineHeight,
      filteredtakeOffTypes,
      referenceInchesPerPixels,
    } = props.modeConfig;
    const isShiftKey = event.sourceEvent.shiftKey;

    const modeDetails = getPipelineModeDetails(pipelineMode);

    if (modeDetails?.isEditMode) {
      const isShiftKey = event.sourceEvent.shiftKey;
      const picks = getPickedPipes(event.picks);

      if (
        picks?.length > 0 &&
        picks[0]?.geometry?.type === GEOJSON_TYPES.Polygon
      ) {
        const featureId = picks[0]?.properties?.id;

        if (isShiftKey) {
          if (selected.includes(featureId)) {
            setSelected(selected.filter((id: any) => id !== featureId));
          } else {
            setSelected([...selected, featureId]);
          }
        } else {
          setSelected([featureId]);
        }
      }

      if (!picks?.length) {
        setSelected([]);
        this._selection = new Set();
        this._hoveredFeature = null;
      }
    }

    if (modeDetails?.isEntryMode) {
      if (!this._arrowFeature || this._arrowFeature.properties._finalized) {
        this._arrowFeature = {
          type: GEOJSON_TYPES.Feature,
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates: [event.mapCoords, event.mapCoords],
          },
          properties: {
            opacity: 100,
            thinkness: 10,
            _finalized: false,
            color: RGB_COLORS.RED,
            border: RGB_COLORS.RED,
            types: [PIPE_VECTOR_TYPE],
          },
        };
      } else {
        this._arrowFeature.properties._finalized = true;

        const arrowFeature = makeArrowFromLine(this._arrowFeature, 45);
        this._arrowFeature = arrowFeature;

        this.handleUpdatePipelineFomAPI(props);
        this._resetState();
      }
    }

    if (modeDetails.isPipelineMode) {
      const nearestCoordinates = isShiftKey
        ? null
        : this._nearestPoint?.geometry?.coordinates;

      const coordinates = this.mainFeature?.geometry?.coordinates;
      const lastCoordinate =
        coordinates?.length > 0
          ? Array.isArray(last(coordinates[0]))
            ? last(coordinates[0])
            : last(coordinates)
          : null;

      if (this._nearestPoint && !isShiftKey) {
        const updatedData = this._nearestPoint.properties.snapped
          ? this._pipelines
          : getUpdatedPipelines(
              this._pipelines,
              this._nearestPoint,
              colorMap,
              referenceInchesPerPixels
            );
        this._pipelines = updatedData.map(roundFeatureCoords);
      }

      let mapCoords = nearestCoordinates || lastCoordinate || event.mapCoords;

      const heightValue = -(pipelineHeight || 0) / referenceInchesPerPixels;
      mapCoords =
        mapCoords.length === 2 ? [...mapCoords, heightValue] : mapCoords;

      this.addClickSequence({ mapCoords });
      if (this._isHeightMode) {
        this._isHeightMode = false;
      }
    }

    if (modeDetails.isDrainMode) {
      const mapCoords = event.mapCoords;
      const countClassName = pipelineMode;
      const nearestCoordinates = isShiftKey
        ? null
        : this._nearestPoint?.geometry?.coordinates;

      if (nearestCoordinates && !isShiftKey) {
        const updatedData = this._nearestPoint.properties.snapped
          ? this._pipelines
          : getUpdatedPipelines(
              this._pipelines,
              this._nearestPoint,
              colorMap,
              referenceInchesPerPixels
            );

        const parentFeature = this._pipelines.find(
          (p: any) =>
            p.properties.id === this?._nearestPoint?.properties?.parentId
        );

        const newDrainLine = getNewDrainLine(
          mapCoords,
          nearestCoordinates,
          colorMap,
          referenceInchesPerPixels,
          parentFeature?.properties
        );

        this._pipelines = [...updatedData, roundFeatureCoords(newDrainLine)];
        this._joints = generateJoints(
          this._pipelines,
          this._cache,
          referenceInchesPerPixels
        );
      }

      const pointFeatureGeometry = {
        type: GEOJSON_TYPES.Point,
        coordinates: [mapCoords[0], mapCoords[1]],
      };

      const pointFeatures = this.getAddFeatureAction(
        pointFeatureGeometry,
        view,
        filteredtakeOffTypes,
        countClassName,
        modeId,
        props.featureAccessKey,
        colorMap
      );

      if (pointFeatures?.length) {
        this.addFeatures(pointFeatures);
        this.applyActions(props, true, {
          recalculateClassifications: true,
        });
      }

      this.generateMesh(referenceInchesPerPixels);
    }

    this._joints = generateJoints(
      this._pipelines,
      this._cache,
      referenceInchesPerPixels
    );
  };

  handleStartDragging = (event: any, props: any) => {
    const { isFocused, useCanvasPan } = props.modeConfig;
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);

    if (useCanvasPan || isFocused) {
      return;
    }

    if (modeDetails.isEditMode) {
      const isShiftKey = event.sourceEvent.shiftKey;
      this._isDragging = true;

      if (isShiftKey) {
        this._draggingStartCoords = event.mapCoords;
      } else {
        const picks = getPickedPipes(event.picks);

        const selectedPipes = this._pipelines.filter((p: any) =>
          this._selection.has(p.properties.id)
        );

        if (picks?.length > 0) {
          this._draggingFeatures = picks[0]?.properties?.editHandleType
            ? picks
            : selectedPipes;
          this._featuresBeforeDragging = [...this._pipelines];
        }
      }
    }
  };

  handleDragging = (event: any, props: any) => {
    const {
      colorMap,
      isFocused,
      useCanvasPan,
      pipelineMode,
      isLengthSnapping,
      referenceInchesPerPixels,
    } = props.modeConfig;
    if (useCanvasPan || isFocused) {
      return;
    }

    const modeDetails = getPipelineModeDetails(pipelineMode);

    if (this._isDragging && modeDetails.isEditMode) {
      const isCommandOrAlt =
        event.sourceEvent.metaKey || event.sourceEvent.altKey;
      const isControlKey = event.sourceEvent.ctrlKey;
      const isShiftKey = event.sourceEvent.shiftKey;

      const isDraggingFeature =
        this._draggingFeatures.length > 0 &&
        this._draggingFeatures[0].geometry.type === GEOJSON_TYPES.LineString;

      const isDraggingHandle =
        this._draggingFeatures.length > 0 &&
        this._draggingFeatures[0].properties?.editHandleType;

      const dragginFeatures = [...this._draggingFeatures].map(
        (f: any) =>
          this._featuresBeforeDragging.find(
            (p: any) => p.properties.id === f.properties.id
          ) || f
      );

      if (isControlKey) {
        const distance = distance2d(
          event.mapCoords[0],
          event.mapCoords[1],
          event.pointerDownMapCoords[0],
          event.pointerDownMapCoords[1]
        );

        this._pipelines = this._pipelines.map((p: any) => {
          if (this._selection.has(p.properties.id)) {
            const feature = dragginFeatures.find(
              (df: any) => df.properties.id === p.properties.id
            );

            return updatePipelineHeight(
              feature,
              distance,
              referenceInchesPerPixels,
              isCommandOrAlt,
              isShiftKey
            );
          }

          return p;
        });

        return;
      }

      if (isDraggingFeature) {
        let targetCoord = event.mapCoords;
        let snapCoord: any = this._nearestPoint;
        let snapCoordOrigin: any = null;

        if (isCommandOrAlt && snapCoord) {
          this._pipelines.forEach((f: any) => {
            if (this._selection.has(f.properties.id)) {
              const originalFeature = dragginFeatures.find(
                (df: any) => df.properties.id === f.properties.id
              );
              const p0 = f.geometry.coordinates[0];
              const p1 = f.geometry.coordinates[1];

              const dist1 = distance2d(
                p0[0],
                p0[1],
                snapCoord.geometry.coordinates[0],
                snapCoord.geometry.coordinates[1]
              );
              const dist2 = distance2d(
                p1[0],
                p1[1],
                snapCoord.geometry.coordinates[0],
                snapCoord.geometry.coordinates[1]
              );

              const closesestPoint = {
                type: GEOJSON_TYPES.Feature,
                geometry: {
                  type: GEOJSON_TYPES.Point,
                  coordinates:
                    dist1 < dist2
                      ? originalFeature.geometry.coordinates[0]
                      : originalFeature.geometry.coordinates[1],
                },
                properties: {
                  snapped: true,
                  dist: dist1 < dist2 ? dist1 : dist2,
                },
              };

              if (
                closesestPoint.properties.dist <
                  snapCoordOrigin?.properties?.dist ||
                !snapCoordOrigin
              ) {
                snapCoordOrigin = closesestPoint;
              }
            }
          });
        }

        let diffX = targetCoord[0] - event.pointerDownMapCoords[0];
        let diffY = targetCoord[1] - event.pointerDownMapCoords[1];

        if (snapCoord && snapCoordOrigin) {
          diffX =
            snapCoord.geometry.coordinates[0] -
            snapCoordOrigin.geometry.coordinates[0];

          diffY =
            snapCoord.geometry.coordinates[1] -
            snapCoordOrigin.geometry.coordinates[1];
        }

        const newFeatures = translateFeatures(dragginFeatures, diffX, diffY);

        this._pipelines = this._pipelines.map((pipe: any) => {
          const found = newFeatures.find(
            (f: any) => f?.properties?.id === pipe?.properties?.id
          );

          if (found) {
            const pipeFeature = pipelineToPolygon(
              found,
              colorMap,
              referenceInchesPerPixels
            );

            return roundFeatureCoords(pipeFeature);
          }

          return pipe;
        });
      }

      if (isDraggingHandle && dragginFeatures.length > 0) {
        let snapCoord = isCommandOrAlt ? this._nearestPoint : null;

        const feature = dragginFeatures[0];
        const handleFeature = this._draggingFeatures[0];

        const isShiftKey = event.sourceEvent.shiftKey;

        const targetCoord =
          isCommandOrAlt && snapCoord
            ? snapCoord.geometry.coordinates
            : event.mapCoords;

        const newFeature = roundFeatureCoords(
          updatePipeLineFeatureCoordinates(
            feature,
            handleFeature,
            targetCoord,
            colorMap,
            referenceInchesPerPixels,
            isShiftKey,
            isLengthSnapping
          )
        );

        if (newFeature) {
          this._pipelines = this._pipelines.map((pipe: any) =>
            pipe?.properties?.id === newFeature?.properties?.id
              ? roundFeatureCoords(newFeature)
              : pipe
          );
        }
      }
    }
  };

  handleStopDragging = (event: any, props: any) => {
    const mapCoords = event.mapCoords;
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);
    const { referenceInchesPerPixels, setSelected } = props.modeConfig;

    if (this._isDragging && modeDetails.isEditMode) {
      this._isDragging = false;

      this._snapTargets = null;
      this._draggingFeatures = [];
      this._featuresBeforeDragging = [];

      this._joints = generateJoints(
        this._pipelines,
        this._cache,
        referenceInchesPerPixels
      );
      this.generateMesh(referenceInchesPerPixels);
      this._joints = generateJoints(
        this._pipelines,
        this._cache,
        referenceInchesPerPixels
      );

      if (this._draggingStartCoords.length > 0) {
        const boxFeature = getTwoClickPolygon(
          this._draggingStartCoords,
          mapCoords
        );
        const intersections = this._pipelines.filter((p: any) =>
          booleanIntersects(p, boxFeature)
        );

        setSelected(intersections.map((p: any) => p.properties.id));

        this._draggingStartCoords = [];
      } else {
        const editedFeatures = this._pipelines
          .filter((p: any) => this._selection.has(p.properties.id))
          .map((p: any) => {
            const measures = getFeatureMeasures(
              p,
              props.modeConfig.view,
              DEFAULT_PIPLELINES_CLASSiFICATIONS_MAP
            );

            return {
              ...p,
              properties: {
                ...p.properties,
                ...measures,
              },
            };
          });

        this.editFeatures(editedFeatures);
        this.applyActions(props, true, {
          isPipeline: true,
          recalculateClassifications: true,
        });
      }
    }
  };

  handleKeyDown = (event: any, props: any) => {
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);

    const { selected, referenceInchesPerPixels, setSelected, setDefaultMode } =
      props.modeConfig;

    const ctrlOrCmd = event.metaKey || event.ctrlKey || event.altKey;

    if (event.key === KEYS.ENTER) {
      event.preventDefault();
      if (modeDetails.isPipelineMode && this._clickSequence.length > 0) {
        this.handleAddFeature(event, props);
      }
    }

    if (event.key === KEYS.BACKSPACE && modeDetails.isEditMode) {
      event.preventDefault();

      this.deleteFeatures(
        this._pipelines.filter((p: any) => this._selection.has(p.properties.id))
      );

      this.applyActions(props, true, {
        isPipeline: true,
        recalculateClassifications: true,
      });

      this._pipelines = this._pipelines.filter(
        (p: any) => !this._selection.has(p.properties.id)
      );
      setSelected([]);

      this.generateMesh(referenceInchesPerPixels);
      this._joints = generateJoints(
        this._pipelines,
        this._cache,
        referenceInchesPerPixels
      );
    }

    if (
      ctrlOrCmd &&
      (event.key === "a" || event.key === "A") &&
      modeDetails.isEditMode
    ) {
      event.preventDefault();
      event.stopPropagation();

      setSelected(this._pipelines.map((p: any) => p.properties.id));
    }

    if (event.key === KEYS.BACKSPACE && modeDetails.isEntryMode) {
      event.preventDefault();
      this._pipelinesApi = [];
      this._arrowFeature = null;
      this._pipelineApiMesh = null;
    }

    if (event.key === KEYS.ESCAPE) {
      event.preventDefault();
      event.stopPropagation();

      if (this._clickSequence.length > 0 && modeDetails.isPipelineMode) {
        this._resetState();
        return;
      }

      if (
        this?._arrowFeature?.properties?._finalized === false &&
        modeDetails.isEntryMode
      ) {
        this._arrowFeature = null;
        this._resetState();
        return;
      }

      if (selected?.length > 0) {
        setSelected([]);
        return;
      }

      setSelected([]);
      setDefaultMode();
    }

    if (
      modeDetails.isPipelineMode &&
      (event.key === "z" ||
        event.key === "Z" ||
        (ctrlOrCmd && (event.key === "Z" || event.key === "z")))
    ) {
      event.preventDefault();
      const clickSequence = this.getClickSequence();

      if (clickSequence.length > 1) {
        this.setClickSequence(clickSequence.slice(0, -1));
      } else {
        this._resetState();
      }
    }

    if (event.key === "p" || event.key === "P") {
      event.preventDefault();
      event.stopPropagation();
    }

    if (
      modeDetails.isPipelineMode &&
      (event.key === "h" || event.key === "H")
    ) {
      event.preventDefault();
      event.stopPropagation();

      this._isHeightMode = !this._isHeightMode;
    }

    if (modeDetails.isPipelineMode && event.key === KEYS.ARROWUP) {
      event.preventDefault();
      event.stopPropagation();

      const index = PIPELINE_WIDTHS.indexOf(this._pipelineWidth);
      const newPipelineWidthIndex =
        index < PIPELINE_WIDTHS.length - 1 ? index + 1 : 0;
      this._pipelineWidth = PIPELINE_WIDTHS[newPipelineWidthIndex];
    }

    if (modeDetails.isPipelineMode && event.key === KEYS.ARROWDOWN) {
      event.preventDefault();
      event.stopPropagation();

      const index = PIPELINE_WIDTHS.indexOf(this._pipelineWidth);
      const newPipelineWidthIndex =
        index > 0 ? index - 1 : PIPELINE_WIDTHS.length - 1;
      this._pipelineWidth = PIPELINE_WIDTHS[newPipelineWidthIndex];
    }
  };

  handleKeyUp = (event: any, props: any) => {};

  handlePointerMove = (event: any, props: any) => {
    const modeDetails = getPipelineModeDetails(props?.modeConfig?.pipelineMode);

    if (modeDetails.isEditMode && !this._isDragging) {
      const pickedFeature = getPickedPipes(event.picks)[0] || null;
      const pipelineFeature = this._pipelines.find(
        (p: any) => p?.properties?.id === pickedFeature?.properties?.id
      );

      if (pipelineFeature) {
        if (pickedFeature?.properties?.positionIndexes?.length) {
          pipelineFeature.properties.positionIndexes =
            pickedFeature.properties.positionIndexes;
        } else {
          pipelineFeature.properties.positionIndexes = null;
        }

        this._hoveredFeature = pipelineFeature;
      } else {
        this._hoveredFeature = null;
      }
    }

    if (modeDetails.isPipelineMode || modeDetails.isDrainMode) {
      props.onUpdateCursor("crosshair");
    } else {
      props.onUpdateCursor("default");
    }
  };

  handlePropsChanged = (_oldProps: any, newProps: any) => {
    const { hidden, selected } = newProps.modeConfig;

    const didSelectionChange =
      selected.length !== this._selection.size ||
      !(selected || []).every((value: string) => this._selection.has(value)) ||
      selected.some((value: string) => !this._selection.has(value));

    if (didSelectionChange) {
      this._selection = new Set(selected);
    }

    const didHiddenChange =
      hidden.length !== this._hidden.size ||
      !(hidden || []).every((value: string) => this._hidden.has(value)) ||
      hidden.some((value: string) => !this._hidden.has(value));
    if (didHiddenChange) {
      this._hidden = new Set(hidden);
    }
  };
}
