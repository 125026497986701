import { atom, selector } from "recoil";
import Document from "flexsearch/dist/module/document";

import {
  ORG_DELETE_FIELDS,
  SET_DELETE_FIELDS,
  VIEW_DELETE_FIELDS,
  PAGE_DELETE_FIELDS,
  PROJECT_DELETE_FIELDS,
} from "src/UtilComponents/DataManager/const";

export const STORE_SET = "set";
export const STORE_ADD = "add";
export const STORE_SYNC = "sync";
export const STORE_RESET = "reset";
export const STORE_UPDATE = "update";
export const STORE_DELETE = "delete";

interface IStore {
  data: any[];
  count: number;
  error: any;
  index: Document;
  map: Map<string, number>;
  loading: boolean;
}

const DEFAULT_DATA_STATE: IStore = {
  data: [],
  count: 0,
  error: null,
  index: null,
  map: new Map(),
  loading: false,
};

function getCleanedObject(obj: any, delete_keys = []): any {
  // object might be null
  if (!obj || typeof obj !== "object" || !obj?.id) {
    return {};
  }

  return Object.keys(obj)
    .filter((key) => !delete_keys.includes(key))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

interface ISetStore {
  set: any;
  store: any;
  data: any[];
  searchProps: any;
  filterValue?: string;
  delete_keys: string[];
  filterKey: string | null;
}

async function setStore({
  set,
  store,
  data,
  filterKey,
  filterValue,
  searchProps = {},
  delete_keys = [],
}: ISetStore) {
  const hasDeleteKeys = delete_keys.length > 0;
  const shouldFilter = filterKey?.length > 0 && filterValue?.length > 0;

  const map = new Map(data.filter((i) => i?.id).map((i, idx) => [i.id, idx]));
  const index = new Document({
    cache: 100,
    id: "id",
    optimize: true,
    tokenize: "full",
    ...searchProps,
  });

  const processedData = data.reduce((acc, item) => {
    if (hasDeleteKeys) {
      item = getCleanedObject(item, delete_keys);
    }

    if (shouldFilter && item?.[filterKey] !== filterValue) {
      return acc;
    }

    map.set(item.id, acc.length);
    index.add(item);

    acc.push(item);
    return acc;
  }, []);

  set(store, (oldStore: ISetStore) => ({
    ...oldStore,
    map,
    index,
    data: processedData,
    count: processedData.length,
  }));
}

function createSearchStore(key: string, store: any) {
  return selector({
    key: "t_s_index" + key,
    get: ({ get }: any) => {
      return get(store).index;
    },
    cachePolicy_UNSTABLE: {
      eviction: "most-recent",
    },
  });
}

function createLoaderStore(key: string, store: any) {
  return selector({
    key: "t_loading" + key,
    get: ({ get }: any) => {
      return get(store).loading;
    },
    set: ({ set, get }, value = false) => {
      set(store, { ...get(store), loading: value });
    },
    cachePolicy_UNSTABLE: {
      eviction: "most-recent",
    },
  });
}

function createCRUDStore(key: string, store: any, delete_keys = []) {
  return selector({
    key: "t_crud" + key,
    get: () => {
      return this;
    },
    set: (
      { set, get }: any,
      { items = [], action, filterKey, filterValue, searchProps = {} }: any
    ) => {
      const query = get(store);
      const dataMap = query.map;
      let data = query.data;

      if (Object.isFrozen(data) || Object.isSealed(data)) {
        data = data.slice();
      }

      const idsToKeep =
        action === STORE_DELETE
          ? new Set(
              (items || [])
                .filter(
                  (i: any) => typeof i === "string" || typeof i === "number"
                )
                .map((i: any) => (typeof i === "object" ? i?.id : i))
                .filter(Boolean)
            )
          : new Set();

      const idsToDelete =
        action === STORE_DELETE
          ? new Set(
              (items || [])
                .filter((i: any) => typeof i === "object" && i?.id)
                .map((i: any) => i.id)
                .filter(Boolean)
            )
          : new Set();

      switch (action) {
        case STORE_RESET:
          setStore({
            set,
            store,
            data: [],
            filterKey,
            filterValue,
            searchProps,
            delete_keys,
          });
          break;

        case STORE_SET:
          setStore({
            set,
            store,
            filterKey,
            filterValue,
            searchProps,
            delete_keys,
            data: items,
          });
          break;

        case STORE_ADD:
        case STORE_UPDATE:
          items.forEach((i: any) => {
            if (i?.id) {
              if (!dataMap.has(i.id)) {
                data.push(i); // Add new item
              } else {
                data[dataMap.get(i.id)] = i; // Update existing item
              }
            }
          });
          setStore({
            set,
            data,
            store,
            filterKey,
            filterValue,
            searchProps,
            delete_keys,
          });
          break;

        case STORE_DELETE:
          let didDelete = false;

          if (idsToDelete.size > 0) {
            const filteredData = data.filter(
              (i: any) => !idsToDelete.has(i?.id)
            );
            didDelete = true;
            data = filteredData;
          }

          if (idsToKeep.size > 0) {
            const filteredData = data.filter((i: any) => idsToKeep.has(i?.id));
            if (data.length !== filteredData.length && !didDelete) {
              didDelete = true;
            }

            data = filteredData;
          }

          if (!didDelete) {
            break;
          }

          setStore({
            set,
            data,
            store,
            filterKey,
            filterValue,
            searchProps,
            delete_keys,
          });
          break;

        default:
          break;
      }
    },
    cachePolicy_UNSTABLE: {
      eviction: "most-recent",
    },
  });
}

function createMapStore(key: string, store: any) {
  return selector({
    key: "t_map" + key,
    get: ({ get }: any) => {
      return get(store).map;
    },
    cachePolicy_UNSTABLE: {
      eviction: "most-recent",
    },
  });
}

function createCountStore(key: string, store: any) {
  return selector({
    key: "t_count" + key,
    get: ({ get }: any) => {
      return get(store).count;
    },
    cachePolicy_UNSTABLE: {
      eviction: "most-recent",
    },
  });
}

function createErrorsStore(key: string, store: any) {
  return selector({
    key: "t_error" + key,
    get: ({ get }: any) => {
      return get(store).error;
    },
    set: ({ set, get }, value = null) => {
      set(store, { ...get(store), error: value });
    },
    cachePolicy_UNSTABLE: {
      eviction: "most-recent",
    },
  });
}

/**
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 *  ________________________________________________ ORGANIZATIONS STORE
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 */

export const organizationsQueryStore = atom({
  key: `t_organizations_query`,
  default: DEFAULT_DATA_STATE,
});

export const organizationsStore = selector({
  key: "t_organizations",
  get: ({ get }) => {
    return get(organizationsQueryStore).data;
  },
  set: (
    { set },
    { data = [], searchProps = {}, filterKey, filterValue }: any
  ) => {
    setStore({
      set,
      data,
      filterKey,
      filterValue,
      searchProps,
      delete_keys: ORG_DELETE_FIELDS,
      store: organizationsQueryStore,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const organizationsCountStore = createCountStore(
  "organizations",
  organizationsQueryStore
);

export const organizationsCRUDStore = createCRUDStore(
  "organizations",
  organizationsQueryStore,
  ORG_DELETE_FIELDS
);

export const organizationsMapStore = createMapStore(
  "organizations",
  organizationsQueryStore
);
export const organizationsErrorStore = createErrorsStore(
  "organizations",
  organizationsQueryStore
);
export const organizationsLoadingStore = createLoaderStore(
  "organizations",
  organizationsQueryStore
);
export const organizationsSearchStore = createSearchStore(
  "organizations",
  organizationsQueryStore
);

/**
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 *  _____________________________________________________ PROJECTS STORE
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 */

export const projectsQueryStore = atom({
  key: `t_projects_query`,
  default: DEFAULT_DATA_STATE,
});

export const projectsStore = selector({
  key: "t_projects",
  get: ({ get }) => {
    return get(projectsQueryStore).data;
  },
  set: (
    { set },
    { data = [], searchProps = {}, filterKey, filterValue }: any
  ) => {
    setStore({
      set,
      data,
      filterKey,
      searchProps,
      filterValue,
      delete_keys: PROJECT_DELETE_FIELDS,
      store: projectsQueryStore,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const projectsMapStore = createMapStore("projects", projectsQueryStore);
export const projectsCRUDStore = createCRUDStore(
  "projects",
  projectsQueryStore,
  PROJECT_DELETE_FIELDS
);
export const projectsCountStore = createCountStore(
  "projects",
  projectsQueryStore
);
export const projectsErrorStore = createErrorsStore(
  "projects",
  projectsQueryStore
);
export const projectsLoadingStore = createLoaderStore(
  "projects",
  projectsQueryStore
);
export const projectsSearchStore = createSearchStore(
  "projects",
  projectsQueryStore
);

/**
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 *  _________________________________________________________ SETS STORE
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 */

export const setsQueryStore = atom({
  key: `t_sets_query`,
  default: DEFAULT_DATA_STATE,
});

export const setsStore = selector({
  key: "t_sets",
  get: ({ get }) => {
    return get(setsQueryStore).data;
  },
  set: ({ set }, { data = [], searchProps, filterKey, filterValue }: any) => {
    setStore({
      set,
      data,
      filterKey,
      searchProps,
      filterValue,
      delete_keys: SET_DELETE_FIELDS,
      store: setsQueryStore,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const setsMapStore = createMapStore("sets", setsQueryStore);
export const setsCRUDStore = createCRUDStore(
  "sets",
  setsQueryStore,
  SET_DELETE_FIELDS
);
export const setsCountStore = createCountStore("sets", setsQueryStore);
export const setsErrorStore = createErrorsStore("sets", setsQueryStore);
export const setsSearchStore = createSearchStore("sets", setsQueryStore);
export const setsLoadingStore = createLoaderStore("sets", setsQueryStore);

/**
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 *  ________________________________________________________ PAGES STORE
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 */

export const pagesQueryStore = atom({
  key: `t_pages_query`,
  default: DEFAULT_DATA_STATE,
});

export const pagesStore = selector({
  key: "t_pages",
  get: ({ get }) => {
    return get(pagesQueryStore).data;
  },
  set: (
    { set },
    { data = [], searchProps = {}, filterKey, filterValue }: any
  ) => {
    setStore({
      set,
      data,
      filterKey,
      searchProps,
      filterValue,
      delete_keys: PAGE_DELETE_FIELDS,
      store: pagesQueryStore,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const pagesMapStore = createMapStore("pages", pagesQueryStore);
export const pagesCRUDStore = createCRUDStore(
  "pages",
  pagesQueryStore,
  PAGE_DELETE_FIELDS
);
export const pagesCountStore = createCountStore("pages", pagesQueryStore);
export const pagesErrorStore = createErrorsStore("pages", pagesQueryStore);
export const pagesSearchStore = createSearchStore("pages", pagesQueryStore);
export const pagesLoadingStore = createLoaderStore("pages", pagesQueryStore);

/**
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 *  ________________________________________________________ VIEWS STORE
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 */

export const viewsQueryStore = atom({
  key: `t_views_query`,
  default: DEFAULT_DATA_STATE,
});

export const viewsStore = selector({
  key: "t_views",
  get: ({ get }) => {
    return get(viewsQueryStore).data;
  },
  set: ({ set }, { data = [], searchProps, filterKey, filterValue }: any) => {
    setStore({
      set,
      data,
      filterKey,
      searchProps,
      filterValue,
      delete_keys: VIEW_DELETE_FIELDS,
      store: viewsQueryStore,
    });
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const viewsMapStore = createMapStore("views", viewsQueryStore);
export const viewsCRUDStore = createCRUDStore(
  "views",
  viewsQueryStore,
  VIEW_DELETE_FIELDS
);
export const viewsCountStore = createCountStore("views", viewsQueryStore);
export const viewsErrorStore = createErrorsStore("views", viewsQueryStore);
export const viewsSearchStore = createSearchStore("views", viewsQueryStore);
export const viewsLoadingStore = createLoaderStore("views", viewsQueryStore);
