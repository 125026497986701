import { Feature, FeatureCollection, Polygon, Position } from "geojson";

import GeoJsonEditMode from "../base/GeojsonEditMode";
import { CLASSIFICATION_TYPES, GEOJSON_TYPES } from "../../consts/editor";
import { getTwoClickPolygon, isDragRelevant } from "../../utils/geometry";

export class DrawRectangleMode extends GeoJsonEditMode {
  _dragStartScreenCoords: Position;
  _dragStopScreenCoords: Position;

  handleClick = (event: any, props: any) => {
    if (!props?.modeConfig?.dragOnly) {
      this.addClickSequence(event);
      this.checkAndFinishPolygon(props);
    }
  };

  handleStartDragging = (event: any, props: any) => {
    if (props.modeConfig.useCanvasPan) {
      return;
    }

    this._dragStartScreenCoords = event.screenCoords;

    this.addClickSequence(event);
  };

  handleStopDragging = (event: any, props: any) => {
    if (props.modeConfig.useCanvasPan) {
      return;
    }

    this._dragStopScreenCoords = event.screenCoords;

    if (
      isDragRelevant([this._dragStartScreenCoords, this._dragStopScreenCoords])
    ) {
      this.addClickSequence(event);
    }
    this.checkAndFinishPolygon(props);
  };

  checkAndFinishPolygon = (props: any) => {
    const { activeClassification } = props.modeConfig;

    const clickSequence = this.getClickSequence();
    const tentativeFeature = this.getTentativeGuide(props);

    if (
      clickSequence.length > 1 &&
      tentativeFeature &&
      tentativeFeature.geometry.type === GEOJSON_TYPES.Polygon
    ) {
      const geojson = {
        type: GEOJSON_TYPES.Feature,
        properties: {},
        geometry: {
          type: GEOJSON_TYPES.Polygon,
          coordinates: tentativeFeature.geometry.coordinates,
        },
      };

      const featureClassification =
        activeClassification &&
        activeClassification?.id &&
        activeClassification &&
        activeClassification.type === CLASSIFICATION_TYPES[0]
          ? activeClassification.id
          : null;

      const polygonFeatures = this.getAddFeatureOrBooleanPolygonAction(
        geojson,
        props,
        featureClassification
      );

      if (polygonFeatures?.length > 0) {
        this.addFeatures(polygonFeatures);
        this.applyActions(props, true, {
          screenCoords: [
            this._dragStartScreenCoords,
            this._dragStopScreenCoords,
          ],
        });
      }

      this.resetClickSequence();
    }
  };

  getGuides = (props: any) => {
    const { lastPointerMoveEvent } = props;

    const clickSequence = this.getClickSequence();

    const guides: FeatureCollection = {
      type: GEOJSON_TYPES.FeatureCollection,
      features: [],
    };

    if (clickSequence.length === 0 || !lastPointerMoveEvent) {
      // nothing to do yet
      return guides;
    }

    const corner1 = clickSequence[0];
    const corner2 = lastPointerMoveEvent.mapCoords;

    const polygon = this.getTwoClickPolygon(corner1, corner2);
    if (polygon) {
      guides.features.push({
        type: GEOJSON_TYPES.Feature,
        properties: {
          shape: polygon.properties && polygon.properties.shape,
          guideType: "tentative",
        },
        geometry: polygon.geometry,
      });
    }

    return guides;
  };

  getTwoClickPolygon(coord1: Position, coord2: Position): Feature<Polygon> {
    return getTwoClickPolygon(coord1, coord2);
  }

  handlePointerMove(event: any, props: any) {
    props.onUpdateCursor("crosshair");
  }

  createTentativeFeature = (props: any) => {
    const { lastPointerMoveEvent } = props;
    const clickSequence = this.getClickSequence();

    const lastCoords = lastPointerMoveEvent
      ? [lastPointerMoveEvent.mapCoords]
      : [];

    let tentativeFeature;
    if (clickSequence.length === 1) {
      tentativeFeature = this.getTwoClickPolygon(
        clickSequence[0],
        lastCoords[0]
      );
    }

    return tentativeFeature;
  };

  handleKeyDown = (event: any, props: any) => {
    if (event.key === "Escape") {
      this.resetClickSequence();
    }
  };
}
