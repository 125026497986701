import * as amplitude from "@amplitude/analytics-browser";
import { Identify } from '@amplitude/analytics-browser';  // Add this import
import { AnalyticsBrowser } from '@segment/analytics-next';
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import config from "../config";

const appCues = window.Appcues;
let analytics = null;
const ENV = "prod";
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// AMPLITUDE
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------

export const initAmplitude = () => {
  if (config.api.base.includes(ENV)) {
    const key = import.meta.env.VITE_REACT_APP_AMPLITUDE;
    try {
      const sessionReplayTracking = sessionReplayPlugin();
      amplitude.add(sessionReplayTracking);
      amplitude.init(key);
      window.amplitude = amplitude;
    } catch (e) {
      console.error("amplitude error : ", e);
    }
  }
};

export const setAmplitudeUserDevice = (installationToken) => {
  if (config.api.base.includes(ENV)) {
    try {
      amplitude.setDeviceId(installationToken);
    } catch (e) {
      console.error("amplitude error : ", e);
    }
  }
};

export const setAmplitudeUserId = (userId) => {
  if (config.api.base.includes(ENV)) {
    try {
      amplitude.setUserId(userId);
    } catch (e) {
      console.error("amplitude error : ", e);
    }
  }
};

export const setAmplitudeUserProperties = (properties, organization_id) => {
  if (config.api.base.includes(ENV)) {
    try {
      const identify = new Identify();
      Object.entries(properties).forEach(([key, value]) => {
        identify.set(key, value);
      });
      
      amplitude.identify(identify);
      amplitude.group(properties.org.id, {
        name: properties.org.name,
        plan: properties.org.payment_data
      });
    } catch (e) {
      console.error("amplitude error : ", e);
    }
  }
};

export const sendAmplitudeData = (eventType, eventProperties = {}) => {
  try {
    amplitude.logEvent(eventType, eventProperties);
  } catch (e) {
    console.error("amplitude error : ", e);
  }
};

// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// APPCUES
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------

const APPCUES_ENVS = ["prod", "staging"];

export const sendAppCuesPageChange = () => {
  if (APPCUES_ENVS.some((env) => config.api.base.includes(env)) && appCues) {
    try {
      appCues.page();
    } catch (e) {
      console.error("appcues error : ", e);
    }
  }
};

export const sendAppCuesUserProperties = (userId, properties) => {
  if (APPCUES_ENVS.some((env) => config.api.base.includes(env)) && appCues) {
    try {
      appCues.identify(userId, properties);
    } catch (e) {
      console.error("appcues error : ", e);
    }
  }
};

export const sendAppCuesEvent = (eventType, eventProperties = {}) => {
  if (APPCUES_ENVS.some((env) => config.api.base.includes(env)) && appCues) {
    try {
      appCues.track(eventType, eventProperties);
    } catch (e) {
      console.error("appcues error : ", e);
    }
  }
};

// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// Segment
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------

export const initSegment = () => {
  if (config.api.base.includes(ENV)) {
    const key = import.meta.env.VITE_REACT_APP_SEGMENT;
    try {
      analytics = AnalyticsBrowser.load({ writeKey: key })
    } catch (e) {
      console.error("segment error : ", e);
    }
  }
};

export const setSegmentUser = (user, organization) => {
  if (config.api.base.includes(ENV)) {
    try {
      const logged_user = user;
      logged_user.first_name = logged_user.name?.split(" ")[0];
      logged_user.last_name = logged_user.name?.split(" ")[1];

      Object.assign(logged_user, logged_user.events);
      logged_user.orgtype = logged_user.org.type;
      logged_user.orgstate = logged_user.org.state;
      
      analytics.identify(logged_user.id, logged_user);

      analytics.group(organization.id, {
        name: organization.name,
        plan: organization.payment_data
      })
    } catch (e) {
      console.error("Segment error : ", e);
    }
  }
};


// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// COMMON
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------

export const sendEvent = (eventType, eventProperties = {}) => {
  if (config.api.base.includes(ENV)) {
    sendAppCuesEvent(eventType, eventProperties);
    sendAmplitudeData(eventType, eventProperties);
  }
};
