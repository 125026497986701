import styled from "styled-components";

import { addOpacity } from "../../utils/style";

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`;

export const Lines = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

export const Area = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid ${(props) => props.color};
  background-color: ${(props) => addOpacity(props.color, 50)};
  background: repeating-linear-gradient(
    45deg,
    ${(props) => props.color},
    ${(props) => props.color} 2px,
    ${(props) => props.color + "50"} 1px,
    ${(props) => props.color + "50"} 4px
  );
`;

export const Circle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid ${(props) => props.color};
  background-color: ${(props) => addOpacity(props.color, 80)};
`;

export const LLine = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: ${(props) => props.color};

  ::before {
    content: "";
    position: absolute;
    left: 0;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: ${(props) => props.color};
  }

  ::after {
    content: "";
    position: absolute;
    right: 0;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: ${(props) => props.color};
  }
`;

export const CtxContainer = styled.div`
  width: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Ctx = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2px;
  height: 2px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
