import React, { forwardRef } from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import * as P from "./props";
import * as S from "./styles";
import { CSS } from "../../../consts/style";

const Tooltip: any = forwardRef<P.TooltipElement, P.TooltipProps>(
  (
    {
      open,
      content,
      children,
      defaultOpen,
      onOpenChange,
      isDark = true,
      side = "top",
      align = "center",
      sideOffset = 4,
      alignOffset = 0,
      delayDuration = 0,
      collisionPadding = 10,
      disableHoverableContent = true,
      fontColor,
      backgroundColor,
      ...tooltipContentProps
    }: P.TooltipProps,
    forwardedRef
  ) => {
    const rootProps = {
      open,
      defaultOpen,
      onOpenChange,
      delayDuration,
      disableHoverableContent,
    };

    return (
      <TooltipPrimitive.Provider>
        <TooltipPrimitive.Root {...rootProps}>
          <TooltipPrimitive.Trigger asChild>
            {children as any}
          </TooltipPrimitive.Trigger>

          <TooltipPrimitive.Portal>
            {!!content && (
              <S.StyledTooltipContent
                side={side}
                align={align}
                asChild={false}
                ref={forwardedRef}
                sideOffset={sideOffset}
                alignOffset={alignOffset}
                collisionPadding={collisionPadding}
                style={{
                  color: fontColor ? fontColor : isDark ? CSS.WHITE : CSS.BLACK,
                  boxShadow: isDark ? "" : CSS.BOX_SHADOW,
                  backgroundColor: backgroundColor
                    ? backgroundColor
                    : isDark
                    ? CSS.BLACK
                    : CSS.GRAY1,
                  overflowWrap: "break-word",
                }}
                {...tooltipContentProps}
              >
                {content as any}
              </S.StyledTooltipContent>
            )}
          </TooltipPrimitive.Portal>
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    );
  }
);

Tooltip.displayName = "Tooltip";

export default Tooltip;
