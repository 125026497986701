import KDBush from "./kdbush";
import kinks from "@turf/kinks";
import bearing from "@turf/bearing";
import turfUnion from "@turf/union";
import lineArc from "@turf/line-arc";
import { point } from "@turf/helpers";
import turfDistance from "@turf/distance";
import destination from "@turf/destination";
import turfSmooth from "@turf/polygon-smooth";
import { Feature, Geometry, Position } from "geojson";
import turfTransformRotate from "@turf/transform-rotate";
import pointToLineDistance from "@turf/point-to-line-distance";

import { isNumber } from "./math";
import { passThrough } from "./function";

import {
  SNAP_ANGLES,
  MODE_SPHERE,
  MODE_RECTANGLE,
  DEFAULT_PADDING,
  ARC_MODE_CIRCLE,
  ARC_MODE_QUADRATIC,
  STRAIGHT_SNAP_ANGLES,
  SNAP_ANGLES_S,
} from "../consts/coordinates";
import { GEOJSON_TYPES } from "../consts/editor";
import { RGBA_COLORS, RGB_COLORS } from "../consts/style";
import {
  PIPELINE_LENGTHS,
  PIPELINE_WIDTHS_LABELS,
  PIPELINE_WIDTHS_MAP,
} from "../consts/pipeline";

import IView from "../types/IView";
import { cond } from "./functional/logic";
import { valueof } from "../types/valueof";
import { equals } from "./functional/object";
import { ILatLng } from "../types/coordinates";
import { divide, multiply } from "./functional/math";
import { head, init, last } from "./functional/list";
import { always, curry, pipe } from "./functional/function";

import { pipelineToPolygon } from "./pipeline";
import { generateId, prettyInches } from "./string";
import { getEditHandlesForGeometry } from "./modes";
import lineIntersect from "../utils/turf/line-intersect";
import { FeatureTypesWithCoordinates } from "../modes/types";
import { DISTANCE_THRESHOLD, INCHES_TO_FEET } from "../consts/geometry";
import {
  getCoordAtPosition,
  getFeatureMeasures,
} from "../modes/base/ImmutableLayersData";

export function calculatePixelDistanceRef(
  ll1: ILatLng,
  ll2: ILatLng,
  width: number,
  bounds: number[][]
) {
  const distance = Math.hypot(
    (ll2?.lng || 0) - (ll1?.lng || 0),
    (ll2?.lat || 0) - (ll1?.lat || 0)
  );

  // convert distance from bounds-relative to image-size-relative.
  const imageToBoundsRatio = width / bounds[1][0];
  return distance * imageToBoundsRatio;
}

export function calculateDistance(p1: number[], p2: number[]) {
  return Math.hypot(p2[0] - p1[0], p2[1] - p1[1]);
}

export function calculateInchesDistanceRef(
  ll1: ILatLng,
  ll2: ILatLng,
  width: number,
  bounds: Position[],
  dpi: number,
  scale_real: number,
  scale_drawing: number
) {
  const pixelDistance = calculatePixelDistanceRef(ll1, ll2, width, bounds);
  return pixelDistance * (1 / dpi) * (scale_real / scale_drawing);
}

export function getFePerimeter(
  type: string,
  coords: ILatLng[],
  width: number,
  bounds: Position[],
  dpi: number,
  scale_real: number,
  scale_drawing: number
) {
  if (!Array.isArray(coords)) {
    throw new Error("Input is not an array.");
  }

  return coords?.reduce((perimeter: number, ll: ILatLng, index: number) => {
    const next =
      type === GEOJSON_TYPES.LineString ||
      type === GEOJSON_TYPES.MultiLineString
        ? coords[index + 1] || coords[index]
        : coords[index + 1] || coords[0];
    return (
      perimeter +
      calculateInchesDistanceRef(
        ll,
        next,
        width,
        bounds,
        dpi,
        scale_real,
        scale_drawing
      )
    );
  }, 0);
}

export function getPerimeterInches(
  type: string,
  coords: any,
  width: number,
  bounds: Position[],
  dpi: number,
  scale_real: number,
  scale_drawing: number
) {
  if (!coords || type === GEOJSON_TYPES.Point) return 0;

  function getSubPerimeter(sub: ILatLng[][][] | ILatLng[][]) {
    const perimeters: number[] = sub.map((item: ILatLng[][] | ILatLng[]) => {
      if (Array.isArray(item[0])) {
        return getSubPerimeter(item as ILatLng[][]);
      } else {
        return (
          getFePerimeter(
            type,
            item as ILatLng[],
            width,
            bounds,
            dpi,
            scale_real,
            scale_drawing
          ) / INCHES_TO_FEET
        );
      }
    });

    return (
      perimeters[0] +
      perimeters
        .slice(1)
        .reduce((n: number, perimeter: number) => n + perimeter, 0)
    );
  }

  if (Array.isArray(coords) && Array.isArray(coords[0])) {
    return getSubPerimeter(coords);
  } else {
    return (
      getFePerimeter(
        type,
        coords,
        width,
        bounds,
        dpi,
        scale_real,
        scale_drawing
      ) / 12
    );
  }
}

type GetCoordsType =
  | Position
  | (Position | Position[] | Position[][] | Position[][][])[];

const isPosition = (value: number | GetCoordsType): value is Position => {
  return (
    Array.isArray(value) &&
    value.length >= 2 &&
    (value as [unknown, unknown]).every((entry) => typeof entry === "number")
  );
};

const isPositionArray = (
  value: Exclude<GetCoordsType, Position>
): value is Position[] =>
  ((Array.isArray(value) && value) || []).every(isPosition);

const getArrayDepth = (value: number | GetCoordsType): number => {
  if (!Array.isArray(value)) return 0;

  return getArrayDepth(value[0]) + 1;
};

const extractCoords = (
  coords: GetCoordsType
): ILatLng | (ILatLng | ILatLng[] | ILatLng[][])[] => {
  if (isPosition(coords)) {
    const [lat, lng] = coords;

    return { lat, lng };
  } else if (isPositionArray(coords) && equals(head(coords), last(coords))) {
    return init(coords).map(extractCoords) as ILatLng[];
  }

  return coords.map(extractCoords) as ILatLng[][] | ILatLng[][][];
};

export const getCoords = (coords: GetCoordsType): any => {
  const arrayDepth = getArrayDepth(coords);

  if (arrayDepth === 2) {
    return extractCoords([coords as Position[]]);
  }

  if (arrayDepth === 1) {
    return [[null, null]];
  }

  return extractCoords(coords);
};

export function getPolygonArea(poly: ILatLng[]): number {
  const pointsCount = poly.length;
  let area = 0.0;

  if (!pointsCount) return 0;

  const points = poly.map((ll) => ({
    x: ll?.lat || 0,
    y: ll?.lng || 0,
  }));

  if (pointsCount > 2) {
    for (let i = 0; i < pointsCount; i++) {
      const curr = points[i];
      const next = points[(i + 1) % pointsCount];
      area += curr.x * next.y;
      area -= curr.y * next.x;
    }
  }

  return 0.5 * Math.abs(area);
}

function getSubPolyArea(sub: ILatLng[][] | ILatLng[][][]): number {
  const areas = sub.map(function (item) {
    if (Array.isArray(item[0])) {
      return getSubPolyArea(item as ILatLng[][]);
    } else {
      return getPolygonArea(item as ILatLng[]);
    }
  });
  return (
    areas[0] -
    areas.slice(1).reduce((n: number, area: number): number => n + area, 0)
  );
}

export function getAreaSquareInches(
  type: string,
  coords: ILatLng[][][] | ILatLng[][] | ILatLng[],
  dpi: number,
  scale_real: number,
  scale_drawing: number
): number {
  if (type === GEOJSON_TYPES.MultiPolygon) {
    return coords
      .map((c) =>
        getAreaSquareInches(
          GEOJSON_TYPES.Polygon,
          c as ILatLng[][],
          dpi,
          scale_real,
          scale_drawing
        )
      )
      .reduce((a, b) => a + b, 0);
  }

  if (type === GEOJSON_TYPES.Polygon) {
    const ll = coords;
    const ratio = (1 / dpi) * (scale_real / scale_drawing);

    const area =
      Array.isArray(ll) && Array.isArray(ll[0])
        ? getSubPolyArea(ll as ILatLng[][]) / 144
        : getPolygonArea(ll as ILatLng[]) / 144;

    return area * ratio * ratio;
  }
  return 0;
}

export const getRecursivePaddingFunction = (padding: number) => {
  const roundingForMultiplicationFunction =
    padding < 1 ? Math.round : passThrough;

  return function paddingFunction(coordinate: any): any {
    if (!Array.isArray(coordinate)) {
      return roundingForMultiplicationFunction(coordinate / padding);
    }

    if (Array.isArray(coordinate[0])) {
      return coordinate.map(paddingFunction);
    }
    return coordinate.map(
      pipe(divide(padding), roundingForMultiplicationFunction)
    );
  };
};

export const updateGeometryWithPadding = curry(
  (padding: number, feature: Feature): Feature => ({
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: (
        feature.geometry as FeatureTypesWithCoordinates
      ).coordinates.map(getRecursivePaddingFunction(padding)) as Position[][][],
    } as Geometry,
  })
);

export function snapToDegAbs(
  p: any,
  mapCoords: any,
  snapAngles: number[] = SNAP_ANGLES
): number[] | null {
  p = p.map(divide(DEFAULT_PADDING));
  mapCoords = mapCoords[0].map(divide(DEFAULT_PADDING));
  const lineString: any = {
    type: "LineString",
    coordinates: [p, p],
  };

  const ddistance = pointToLineDistance(mapCoords, lineString);

  const absBearing = bearing(p, mapCoords);
  const distances = snapAngles.map((i: number) => Math.abs(i - absBearing));
  const distancesIndex = distances.indexOf(Math.min(...distances));
  const minAngle = snapAngles[distancesIndex];

  const snappedPoint = destination(p, ddistance, minAngle);

  if (snappedPoint) {
    return snappedPoint.geometry.coordinates.map(multiply(DEFAULT_PADDING));
  }
  return null;
}

export function snapToDegRelative(
  p1: any,
  p2: any,
  mapCoords: any,
  snapAngles: number[] = SNAP_ANGLES
): number[] | null {
  p1 = p1.map(divide(DEFAULT_PADDING));
  p2 = p2.map(divide(DEFAULT_PADDING));
  const scaledMapCoords = mapCoords.map(divide(DEFAULT_PADDING));

  const lineString: any = {
    type: "LineString",
    coordinates: [p2, p2],
  };

  const ddistance = pointToLineDistance(scaledMapCoords, lineString);

  const lineBearing = bearing(p1, p2);
  const pBearing = bearing(p2, scaledMapCoords);

  const lineDistances = snapAngles.map((i: number) =>
    Math.abs(i - lineBearing)
  );
  const minLineDistance = Math.min(...lineDistances);
  const lineDistanceIndex = lineDistances.indexOf(minLineDistance);
  const lineDistanceAngle = snapAngles[lineDistanceIndex];

  const distances = snapAngles.map((i: number) => Math.abs(i - pBearing));
  const minDistance = Math.min(...distances);
  const distancesIndex = distances.indexOf(minDistance);
  const minAngle = snapAngles[distancesIndex];

  const minAbsoluteDistance = Math.min(
    ...STRAIGHT_SNAP_ANGLES.map((i: number) => Math.abs(i - pBearing))
  );

  const minRelativeDistance =
    minAngle +
    (lineDistanceAngle - lineBearing > 0 ? -minLineDistance : minLineDistance);

  const destinationAngle =
    Math.abs(minAbsoluteDistance) < 2 ? minAngle : minRelativeDistance;

  const snappedPoint = destination(p2, ddistance, destinationAngle);

  if (snappedPoint) {
    return snappedPoint.geometry.coordinates.map(multiply(DEFAULT_PADDING));
  }
  return null;
}

function projectPointOntoLineSegment(p1: any, p2: any, p3: any) {
  var v1 = [p2[0] - p1[0], p2[1] - p1[1]];
  var v2 = [p3[0] - p1[0], p3[1] - p1[1]];

  var dotProduct = v1[0] * v2[0] + v1[1] * v2[1];
  var lengthSquared = v1[0] * v1[0] + v1[1] * v1[1];

  var t = dotProduct / lengthSquared;
  t = Math.max(0, Math.min(1, t));
  var projection = [p1[0] + t * v1[0], p1[1] + t * v1[1]];

  return projection;
}

export const getSnappedTentative = (
  clickSequence: number[][],
  lastCoords: number[][],
  overrides?: any
): any => {
  const snapAngles = overrides?.angles || SNAP_ANGLES;

  if (!clickSequence?.length || !lastCoords.length) {
    console.error("At least 1 point should be created");
    return { snapped: null, isLast: false };
  }

  if (clickSequence.length >= 2) {
    const p0 = clickSequence[0];

    const p1 = clickSequence[clickSequence.length - 2];
    const p2 = last(clickSequence);
    const snappedToDegPoint = snapToDegRelative(
      p1,
      p2,
      lastCoords[0],
      snapAngles
    );

    if (clickSequence.length > 2 && snappedToDegPoint) {
      const projected = projectPointOntoLineSegment(p2, snappedToDegPoint, p0);

      if (projected) {
        const distanceToProjected = calculateDistance(
          snappedToDegPoint,
          projected
        );

        const snappedAngle = getAngleBetween(p2, projected, p0);

        if (distanceToProjected < 20 && Math.round(snappedAngle) == 90) {
          return { snapped: [projected], isLast: true };
        }
      }
    }

    if (snappedToDegPoint) {
      return { snapped: [snappedToDegPoint], isLast: false };
    }
  } else {
    const p = last(clickSequence);
    const snappedToDegPoint = snapToDegAbs(p, lastCoords, snapAngles);
    if (snappedToDegPoint) {
      return { snapped: [snappedToDegPoint], isLast: false };
    }
  }

  // fallback to just abs 90 deg
  const diffX = last(clickSequence)[0] - lastCoords[0][0];
  const diffY = last(clickSequence)[1] - lastCoords[0][1];

  if (Math.abs(diffX) <= Math.abs(diffY)) {
    return {
      snapped: [[last(clickSequence)[0], lastCoords[0][1]]],
      isLast: false,
    };
  } else {
    return {
      snapped: [[lastCoords[0][0], last(clickSequence)[1]]],
      isLast: false,
    };
  }
};

export function getSnapArc(points: any[], switchBearing = false) {
  if (points?.length !== 3) {
    return null;
  }

  const distance = Math.max(
    Math.abs(points[2][0] - points[1][0]),
    Math.abs(points[2][1] - points[1][1])
  );

  const radius = Math.min(Math.max(Math.round(distance / 1000), 0.1), 6);

  const p1 = points[0].map(divide(DEFAULT_PADDING));
  const p2 = points[1].map(divide(DEFAULT_PADDING));
  const p3 = points[2].map(divide(DEFAULT_PADDING));

  const b1 = bearing(p1, p2);
  const b2 = bearing(p3, p2);

  const center = point(p2);

  let arc;

  if (switchBearing) {
    arc = lineArc(center, radius, b2, b1);
  } else {
    arc = lineArc(center, radius, b1, b2);
  }

  arc = turfTransformRotate(arc, 180, {
    pivot: center,
  });

  if (arc) {
    arc.geometry.coordinates = arc.geometry.coordinates.map((ll) =>
      ll.map(multiply(DEFAULT_PADDING))
    );

    return arc;
  }

  return null;
}

function getTentativefeature(
  props: any,
  guideType: any,
  clickSequence: any,
  coordinates: any
) {
  const { activeClassification } = props.modeConfig;

  const pattern = activeClassification?.pattern;
  const color = activeClassification?.colorStyle?.fill;
  const patternSpacing = activeClassification?.patternSpacing;

  return {
    type: GEOJSON_TYPES.Feature,
    properties: {
      guideType,
      color,
      pattern,
      patternSpacing,
    },
    geometry: {
      type:
        clickSequence.length === 1
          ? GEOJSON_TYPES.LineString
          : GEOJSON_TYPES.Polygon,
      coordinates: clickSequence.length === 1 ? coordinates : [coordinates],
    },
  };
}

function getLastAndFirstPointsDists(tentativeFeature: any) {
  let lastPointDist = 0;
  let firstPointDist = 0;

  if (tentativeFeature.geometry.coordinates[0].length > 3) {
    lastPointDist = Math.hypot(
      tentativeFeature.geometry.coordinates[0][0][0] -
        tentativeFeature.geometry.coordinates[0][
          tentativeFeature.geometry.coordinates[0].length - 1
        ][0],
      tentativeFeature.geometry.coordinates[0][0][1] -
        tentativeFeature.geometry.coordinates[0][
          tentativeFeature.geometry.coordinates[0].length - 1
        ][1]
    );

    firstPointDist = Math.hypot(
      tentativeFeature.geometry.coordinates[0][
        tentativeFeature.geometry.coordinates[0].length - 2
      ][0] -
        tentativeFeature.geometry.coordinates[0][
          tentativeFeature.geometry.coordinates[0].length - 1
        ][0],
      tentativeFeature.geometry.coordinates[0][
        tentativeFeature.geometry.coordinates[0].length - 2
      ][1] -
        tentativeFeature.geometry.coordinates[0][
          tentativeFeature.geometry.coordinates[0].length - 1
        ][1]
    );
  }

  return [lastPointDist, firstPointDist];
}

function getSecondaryTentativeFeatures(
  tentativeFeature: any,
  sortedSnapTargets: any,
  snapDistance: number,
  isSnapOn: boolean,
  isAltCtrl: boolean,
  shouldSnapFeatures: boolean,
  isLast: boolean
) {
  const secondaryFeatures = [];

  let closest = sortedSnapTargets.length > 0 ? sortedSnapTargets[0] : null;

  if (isSnapOn || isAltCtrl) {
    if (sortedSnapTargets?.length > 0) {
      if (
        closest?.geometry?.coordinates &&
        closest?.properties?.dist < snapDistance
      ) {
        secondaryFeatures.push({
          ...closest,
          properties: {
            ...closest.properties,
            editHandleType: "snap",
            guideType: "snap",
          },
        });
      } else {
        for (const closestTarget of sortedSnapTargets) {
          secondaryFeatures.push({
            ...closestTarget,
            properties: {
              ...closestTarget.properties,
              editHandleType: "snap",
              guideType: "snap",
            },
          });
        }
      }
    }
  }

  if (shouldSnapFeatures) {
    let snapAngles = {
      ...tentativeFeature,
      properties: {
        guideType: "snap",
      },
    };

    const tentativeCoordinates =
      tentativeFeature.geometry.type === GEOJSON_TYPES.Polygon
        ? tentativeFeature.geometry.coordinates[0]
        : tentativeFeature.geometry.coordinates;

    const isLeft = tentativeCoordinates[0][0] > tentativeCoordinates[1][0];

    if (tentativeFeature.geometry.type === GEOJSON_TYPES.Polygon) {
      const last3 = snapAngles.geometry.coordinates[0].slice(-3);
      const snapArc = getSnapArc(last3, isLeft);

      if (snapArc) {
        snapArc.properties = {
          editHandleType: "snap",
          guideType: "snap",
        };
        secondaryFeatures.push(snapArc);
        snapAngles = {
          ...snapAngles,
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates: last3,
          },
        };

        secondaryFeatures.push(snapAngles);
      }

      if (isLast) {
        const coordinates = tentativeFeature.geometry.coordinates[0];

        const points = [
          coordinates[0],
          coordinates[coordinates.length - 1],
          coordinates[coordinates.length - 2],
        ];

        const isLastLeft =
          coordinates[0][0] > coordinates[coordinates.length - 1][0];
        const lastSnapArc = getSnapArc(points, isLastLeft);

        if (lastSnapArc) {
          lastSnapArc.properties = {
            editHandleType: "snap",
            guideType: "snap",
          };
          secondaryFeatures.push(lastSnapArc);
        }
      }

      if (tentativeFeature?.properties?.closed) {
        let closedSnapAngles = {
          ...tentativeFeature,
          properties: {
            guideType: "snap",
          },
        };
        const closedSnapAnglesCoords = closedSnapAngles.geometry.coordinates[0];
        const closed3 = [
          closedSnapAnglesCoords[1],
          closedSnapAnglesCoords[0],
          closedSnapAnglesCoords[closedSnapAnglesCoords.length - 2],
        ];
        const closedSnapArc = getSnapArc(closed3, !isLeft);

        if (closedSnapArc) {
          closedSnapArc.properties = {
            editHandleType: "snap",
            guideType: "snap",
          };
          secondaryFeatures.push(closedSnapArc);

          closedSnapAngles = {
            ...closedSnapAngles,
            geometry: {
              type: GEOJSON_TYPES.LineString,
              coordinates: closed3,
            },
          };

          secondaryFeatures.push(closedSnapAngles);
        }
      }
    } else {
      const snapArc = getSnapArc(tentativeFeature.geometry.coordinates, isLeft);
      if (snapArc) {
        snapArc.properties = {
          editHandleType: "snap",
          guideType: "snap",
        };
        secondaryFeatures.push(snapArc);
      }

      tentativeFeature = {
        ...tentativeFeature,
        properties: {
          guideType: "snap",
        },
      };
    }
  }

  return secondaryFeatures;
}

function getAngleBetween(p1: number[], p2: number[], p3: number[]) {
  const v1 = [p1[0] - p2[0], p1[1] - p2[1]];
  const v2 = [p3[0] - p2[0], p3[1] - p2[1]];

  const dotProduct = v1[0] * v2[0] + v1[1] * v2[1];

  const m1 = Math.sqrt(v1[0] ** 2 + v1[1] ** 2);
  const m2 = Math.sqrt(v2[0] ** 2 + v2[1] ** 2);

  const angleInRadians = Math.acos(dotProduct / (m1 * m2));

  return (angleInRadians * 180) / Math.PI;
}

function getIntermediatePoint(coordinates: Position[]) {
  coordinates = coordinates.map((p) => p.map(divide(DEFAULT_PADDING)));

  let pt: any;
  if (coordinates.length > 4) {
    const [p1, p2] = [...coordinates];
    const angle1 = bearing(p1, p2);
    const p3 = coordinates[coordinates.length - 3];
    const p4 = coordinates[coordinates.length - 4];
    const angle2 = bearing(p3, p4);

    const angles: any = { first: [], second: [] };
    // calculate 3 right angle points for first and last points in lineString
    [1, 2, 3].forEach((factor) => {
      const newAngle1 = angle1 + factor * 90;
      // convert angles to 0 to -180 for anti-clock and 0 to 180 for clock wise
      angles.first.push(newAngle1 > 180 ? newAngle1 - 360 : newAngle1);
      const newAngle2 = angle2 + factor * 90;
      angles.second.push(newAngle2 > 180 ? newAngle2 - 360 : newAngle2);
    });

    const distance = turfDistance(point(p1), point(p3));
    // Draw imaginary right angle lines for both first and last points in lineString
    // If there is intersection point for any 2 lines, will be the 90 degree point.
    [0, 1, 2].forEach((indexFirst) => {
      const line1: any = {
        type: "LineString",
        coordinates: [
          p1,
          destination(p1, distance, angles.first[indexFirst]).geometry
            .coordinates,
        ],
      };

      [0, 1, 2].forEach((indexSecond) => {
        const line2: any = {
          type: "LineString",
          coordinates: [
            p3,
            destination(p3, distance, angles.second[indexSecond]).geometry
              .coordinates,
          ],
        };
        const fc: any = lineIntersect(line1, line2);
        if (fc && fc.features.length) {
          // found the intersect point
          pt = fc.features[0].geometry.coordinates;
        }
      });
    });
  }

  return pt ? pt.map(multiply(DEFAULT_PADDING)) : null;
}

function finalizedCoordinates(coords: Position[]) {
  // Remove the hovered position
  let coordinates = [[...coords.slice(0, -2), coords[0]]];
  let pt = getIntermediatePoint([...coords]);

  if (!pt) {
    // if intermediate point with 90 degree not available
    // try remove the last clicked point and get the intermediate point.
    const tc = [...coords];
    tc.splice(-3, 1);
    pt = getIntermediatePoint([...tc]);
    if (pt) {
      coordinates = [[...coords.slice(0, -3), pt, coords[0]]];
    }
  } else {
    coordinates = [[...coords.slice(0, -2), pt, coords[0]]];
  }
  return coordinates;
}

function getClosedTentative(feature: any, clickSequence: any, mousePos: any) {
  if (feature?.geometry?.type === GEOJSON_TYPES.Polygon) {
    const coordinates = [...clickSequence, mousePos];

    const firstP: number[] = head(coordinates);
    const dist = calculateDistance(firstP, mousePos);

    if (dist < 50) {
      return {
        ...feature,
        properties: {
          ...feature.properties,
          closed: true,
        },
        geometry: {
          type: "Polygon",
          coordinates: finalizedCoordinates(coordinates),
        },
      };
    }
  }

  return feature;
}

export function getFirstPointSnapped(props: any, context: any) {
  const {
    lastPointerMoveEvent,
    modeConfig: { isSnapOn, snapDistance },
  } = props;

  const isAltCtrl =
    lastPointerMoveEvent?.sourceEvent.ctrlKey ||
    lastPointerMoveEvent?.sourceEvent.metaKey;

  const lastCoords = lastPointerMoveEvent
    ? [lastPointerMoveEvent.mapCoords]
    : [];

  const sortedSnapTargets =
    context?.indexedTargets && lastCoords?.length > 0
      ? context.indexedTargets.within(lastCoords[0][0], lastCoords[0][1], 500)
      : [];
  let closest = sortedSnapTargets.length > 0 ? sortedSnapTargets[0] : null;

  const snappedPoint =
    (isSnapOn || isAltCtrl) &&
    closest?.geometry?.coordinates &&
    closest?.properties?.dist < snapDistance
      ? [closest?.geometry?.coordinates]
      : lastCoords;

  const secondaryFeatures = [];
  if (isSnapOn || isAltCtrl) {
    if (sortedSnapTargets?.length > 0) {
      if (
        closest?.geometry?.coordinates &&
        closest?.properties?.dist < snapDistance
      ) {
        secondaryFeatures.push({
          ...closest,
          properties: {
            ...closest.properties,
            editHandleType: "snap",
            guideType: "snap",
          },
        });
      } else {
        for (const closestTarget of sortedSnapTargets) {
          secondaryFeatures.push({
            ...closestTarget,
            properties: {
              ...closestTarget.properties,
              editHandleType: "snap",
              guideType: "snap",
            },
          });
        }
      }
    }
  }

  return [snappedPoint, ...secondaryFeatures];
}

export interface Context {
  indexedTargets?: {
    within: (x: number, y: number, distance: number) => any[];
  } | null;
  _snapTargets?: {
    features: any[];
  };
  _lock?: boolean;
  _arcs?: any[];
  mainFeature?: any;
  _tempArcMode?: number;
  _isArcMode?: boolean;
  _ignoreClick?: boolean;
  _isControlPoint?: boolean;
  _tempArcSegments?: number;
  _tempArcStartIndex?: number;
  getClickSequence: () => any[];
}

export interface FeatureProps {
  lastPointerMoveEvent: {
    mapCoords: [number, number];
    sourceEvent?: {
      shiftKey?: boolean;
      ctrlKey?: boolean;
      metaKey?: boolean;
    };
  };
  modeConfig: {
    isSnapOn?: boolean;
    snapDistance?: number;
    isMultiLineMode?: boolean;
    automaticSnapping?: boolean;
    activeClassification?: any;
  };
}

export function createTentativeFeature(
  props: FeatureProps,
  context: Context | any,
  guideType: any,
  ignoreSnapping = false,
  ignoreKinks = false,
  overrides = {}
) {
  const { lastPointerMoveEvent } = props;
  const { isSnapOn, snapDistance, isMultiLineMode, automaticSnapping } =
    props.modeConfig;
  const clickSequence = context.getClickSequence();

  const isShiftKey = lastPointerMoveEvent?.sourceEvent?.shiftKey;
  const isAltCtrl =
    lastPointerMoveEvent?.sourceEvent.ctrlKey ||
    lastPointerMoveEvent?.sourceEvent.metaKey;

  let lastCoords: any = lastPointerMoveEvent
    ? [lastPointerMoveEvent.mapCoords]
    : [];

  const previousLastCoords = clickSequence[clickSequence.length - 1];
  const previoudLastCoordHeight = previousLastCoords[2] || 0;
  const distanceToPrevious = calculateDistance(
    lastCoords[0],
    previousLastCoords
  );
  const newHeight = isAltCtrl
    ? 0
    : (isShiftKey ? 1 : -1) *
      Math.abs(previoudLastCoordHeight + distanceToPrevious);

  lastCoords = context?._isHeightMode
    ? [[previousLastCoords[0] || 0, previousLastCoords[1] || 0, newHeight]]
    : previousLastCoords?.length === 3
    ? [[lastCoords[0][0] || 0, lastCoords[0][1] || 0, previoudLastCoordHeight]]
    : lastCoords;

  if (context._isHeightMode) {
    const tentativeFeature3D = getTentativefeature(
      props,
      guideType,
      clickSequence,
      [...clickSequence, ...lastCoords]
    );
    return [
      {
        ...tentativeFeature3D,
        geometry: {
          type: GEOJSON_TYPES.LineString,
          coordinates:
            tentativeFeature3D?.geometry?.type === GEOJSON_TYPES.Polygon
              ? tentativeFeature3D?.geometry?.coordinates[0]
              : tentativeFeature3D?.geometry?.coordinates,
        },
      },
    ];
  }
  /** 3d stuff experiment */

  const sortedSnapTargets =
    context?.indexedTargets && lastCoords?.length
      ? context.indexedTargets.within(lastCoords[0][0], lastCoords[0][1], 500)
      : [];

  let closest = sortedSnapTargets.length > 0 ? sortedSnapTargets[0] : null;

  const is2pointsMode =
    context._isArcMode &&
    [MODE_RECTANGLE, MODE_SPHERE].includes(context._tempArcMode);

  const shouldSnapFeatures =
    !ignoreSnapping &&
    ((isShiftKey && !automaticSnapping) || (automaticSnapping && !isShiftKey));

  let tentativeFeature: any;
  let { snapped, isLast } = getSnappedTentative(
    clickSequence,
    lastCoords,
    overrides
  );

  snapped =
    lastCoords[0].length === 3
      ? [[snapped[0][0], snapped[0][1], lastCoords[0][2]]]
      : snapped;

  const lastFeature =
    (isSnapOn || isAltCtrl) &&
    closest?.geometry?.coordinates &&
    closest?.properties?.dist < snapDistance
      ? [closest?.geometry?.coordinates]
      : lastCoords;

  const coordinates = [
    ...clickSequence,
    ...(shouldSnapFeatures ? snapped : lastFeature),
  ];

  tentativeFeature = getTentativefeature(
    props,
    guideType,
    clickSequence,
    coordinates
  );
  tentativeFeature.properties.arcs = context?._arcs || [];

  if (
    context._isArcMode &&
    is2pointsMode &&
    clickSequence.length === 1 &&
    context._isControlPoint
  ) {
    const startCoords = coordinates[0];
    const endCoords = lastCoords[0];
    const controlCoords = lastCoords[0];

    const arcFn =
      context?._tempArcMode === MODE_SPHERE
        ? getCirclePoints
        : getRectangeCoordinates;

    const arcCoordinates = arcFn(
      startCoords,
      controlCoords,
      endCoords,
      context._tempArcSegments
    );

    const newCoordinates = [startCoords, ...arcCoordinates];
    if (isMultiLineMode) {
      tentativeFeature.geometry.type = GEOJSON_TYPES.LineString;
      tentativeFeature.geometry.coordinates = newCoordinates;
    } else {
      tentativeFeature.geometry.type = GEOJSON_TYPES.Polygon;
      tentativeFeature.geometry.coordinates = [newCoordinates];
    }

    const startPointIdx = 0;
    const endPointIdx = Math.ceil((arcCoordinates.length - 1) / 2);

    const ignoreIndices = new Array(newCoordinates.length - 1)
      .fill(0)
      .map((_, i) => startPointIdx + i + 1);

    const arc = {
      id: generateId(),
      type: context._tempArcMode,
      controlPoint: [0, 0],
      endPointIdx,
      startPointIdx,
      ignoreIndices,
    };

    tentativeFeature.properties.arcs = [arc];

    return [tentativeFeature];
  }

  if (
    context._isArcMode &&
    !is2pointsMode &&
    clickSequence?.length > 1 &&
    context._isControlPoint
  ) {
    const coordinates =
      tentativeFeature.geometry.type === GEOJSON_TYPES.Polygon
        ? tentativeFeature.geometry.coordinates[0]
        : tentativeFeature.geometry.coordinates;

    if (coordinates?.length > 2) {
      coordinates.pop();

      const endCoords = coordinates[coordinates.length - 1];
      const startCoords = coordinates[coordinates.length - 2];
      const controlCoords = lastCoords[0];

      const arcFn =
        context?._tempArcMode === ARC_MODE_CIRCLE
          ? getArcPoints
          : getQuadraticPoints;
      const arcCoordinates = arcFn(
        startCoords,
        controlCoords,
        endCoords,
        context._tempArcSegments
      );

      if (arcCoordinates?.length > 0) {
        coordinates.pop();
        const newCoordinates =
          tentativeFeature.geometry.type === GEOJSON_TYPES.Polygon
            ? [[...coordinates, ...arcCoordinates]]
            : [...coordinates, ...arcCoordinates];

        tentativeFeature.geometry.coordinates = newCoordinates;

        const startPointIdx = clickSequence.length - 2;
        const endPointIdx =
          tentativeFeature.geometry.type === GEOJSON_TYPES.Polygon
            ? newCoordinates[0].length - 1
            : newCoordinates.length - 1;

        const ignoreIndices = new Array(arcCoordinates.length - 1)
          .fill(0)
          .map((_, i) => startPointIdx + i + 1);

        const arc = {
          id: generateId(),
          type: context._tempArcMode,
          controlPoint: controlCoords,
          endPointIdx,
          startPointIdx,
          ignoreIndices,
        };

        tentativeFeature.properties.arcs = [...(context?._arcs || []), arc];

        if (
          tentativeFeature?.geometry?.type === GEOJSON_TYPES.Polygon &&
          isMultiLineMode
        ) {
          tentativeFeature.geometry.type = GEOJSON_TYPES.LineString;
          tentativeFeature.geometry.coordinates =
            tentativeFeature.geometry.coordinates[0];
        }

        return [tentativeFeature];
      }
    }
  }

  if (!isMultiLineMode && shouldSnapFeatures && clickSequence?.length > 3) {
    tentativeFeature = getClosedTentative(
      tentativeFeature,
      clickSequence,
      lastPointerMoveEvent.mapCoords
    );
  }

  const isSelfIntersection =
    !isMultiLineMode &&
    kinks(tentativeFeature as Feature<any>)?.features?.length > 0;

  const isEdgeIntersection =
    !isMultiLineMode &&
    kinks({
      ...tentativeFeature,
      geometry: {
        type: GEOJSON_TYPES.Polygon,
        coordinates: [
          [
            ...tentativeFeature.geometry.coordinates[0],
            tentativeFeature.geometry.coordinates[0][0],
          ],
        ],
      } as any,
    })?.features?.length > 0;

  const [lastPointDist, firstPointDist] =
    getLastAndFirstPointsDists(tentativeFeature);

  if (
    !isMultiLineMode &&
    !ignoreKinks &&
    !ignoreSnapping &&
    (isSelfIntersection || isEdgeIntersection) &&
    lastPointDist > DISTANCE_THRESHOLD &&
    firstPointDist > DISTANCE_THRESHOLD
  ) {
    context._ignoreClick = isSelfIntersection;
    context._lock = isSelfIntersection || isEdgeIntersection;

    tentativeFeature = {
      ...tentativeFeature,
      properties: {
        guideType: "error",
      },
    };
  } else {
    context._ignoreClick = false;
    context._lock = false;
  }

  const secondaryFeatures = getSecondaryTentativeFeatures(
    tentativeFeature,
    sortedSnapTargets,
    snapDistance,
    isSnapOn,
    isAltCtrl,
    shouldSnapFeatures,
    isLast
  );

  if (
    tentativeFeature?.geometry?.type === GEOJSON_TYPES.Polygon &&
    isMultiLineMode
  ) {
    tentativeFeature.geometry.type = GEOJSON_TYPES.MultiLineString;
  }

  return [tentativeFeature, ...secondaryFeatures];
}

// inspired from https://stackoverflow.com/questions/17411991/html5-canvas-rotate-image
export function getRotatedImgBoundingBox(
  width: number,
  height: number,
  rad: number
): number[] {
  const rectProjectedWidth =
    Math.abs(width * Math.cos(rad)) + Math.abs(height * Math.sin(rad));
  const rectProjectedHeight =
    Math.abs(width * Math.sin(rad)) + Math.abs(height * Math.cos(rad));

  const sinHeight = height * Math.abs(Math.sin(rad));
  const cosHeight = height * Math.abs(Math.cos(rad));
  const cosWidth = width * Math.abs(Math.cos(rad));
  const sinWidth = width * Math.abs(Math.sin(rad));

  const imgBboxRad = Math.atan(width / height);

  let x, y;

  if (rad < imgBboxRad) {
    x = Math.min(sinHeight, cosWidth);
    y = 0;
  } else if (rad < Math.PI / 2) {
    x = Math.max(sinHeight, cosWidth);
    y = 0;
  } else if (rad < Math.PI / 2 + imgBboxRad) {
    x = width;
    y = Math.min(cosHeight, sinWidth);
  } else if (rad < Math.PI) {
    x = width;
    y = Math.max(cosHeight, sinWidth);
  } else if (rad < Math.PI + imgBboxRad) {
    x = Math.max(sinHeight, cosWidth);
    y = height;
  } else if (rad < (Math.PI / 2) * 3) {
    x = Math.min(sinHeight, cosWidth);
    y = height;
  } else if (rad < (Math.PI / 2) * 3 + imgBboxRad) {
    x = 0;
    y = Math.max(cosHeight, sinWidth);
  } else if (rad < Math.PI * 2) {
    x = 0;
    y = Math.min(cosHeight, sinWidth);
  }

  return [x, y, rectProjectedWidth, rectProjectedHeight];
}

export function getImageBoundingBox(
  { width = 0, height = 0, tx = 0, ty = 0, scale = 1, rotate = 0 },
  withShift = false
) {
  const x = tx;
  const y = ty;
  const scaledWidth = Math.round(x + width * scale);
  const scaledHeight = Math.round(y + height * scale);

  if (!withShift) return [x, y, scaledWidth, scaledHeight];

  const [, , projectedWidth, projectedHeight] = getRotatedImgBoundingBox(
    width,
    height,
    rotate
  );

  const cx = (scaledWidth - x) / 2;
  const cy = (scaledHeight - y) / 2;
  const shiftedX = Math.round(x + cx - (projectedWidth * scale) / 2);
  const shiftedY = Math.round(y + cy - (projectedHeight * scale) / 2);
  const shiftedWidth = Math.round(x + cx + (projectedWidth * scale) / 2);
  const shiftedHeight = Math.round(y + cy + (projectedHeight * scale) / 2);

  return [shiftedX, shiftedY, shiftedWidth, shiftedHeight];
}

export function degToRad(deg: number) {
  return deg * (Math.PI / 180);
}

export function getBounds(
  b: Position[]
): [Position, Position, Position, Position] {
  return [
    [b[0][0], b[1][1]],
    [b[0][0], b[0][1]],
    [b[1][0], b[0][1]],
    [b[1][0], b[1][1]],
  ];
}

export function getSimpleBounds(coords: Position[]) {
  return coords.reduce(
    ([minX, minY, maxX, maxY], [x, y]) => [
      Math.min(...[x, minX].filter(isNumber)),
      Math.min(...[y, minY].filter(isNumber)),
      Math.max(...[x, maxX].filter(isNumber)),
      Math.max(...[y, maxY].filter(isNumber)),
    ],
    [null, null, null, null]
  );
}

export const getDrawingBounds = (
  vectorData: { vertices?: Position[] },
  width: number,
  height: number
): Position[] => {
  let maxCoord = [0, 0];
  if (!vectorData?.vertices?.length) {
    maxCoord = [width, height];
  } else {
    vectorData.vertices.forEach(([x, y]: [number, number]) => {
      if (x > maxCoord[0]) maxCoord[0] = x;
      if (y > maxCoord[1]) maxCoord[1] = y;
    });
  }
  return [[0, 0], maxCoord];
};

export function cleanEmptyFeatures(features: Feature[]) {
  return features.filter((fe: any) => {
    if (fe?.geometry?.coordinates?.length === 0) return false;
    if (fe?.geometry?.type === GEOJSON_TYPES.Polygon) {
      return fe.geometry.coordinates[0].length > 3;
    }
    return true;
  });
}

export function cleanSingleCoordPolygons(features: Feature[]) {
  if (!features || features?.length === 0) return [];
  return features.filter(
    (fe: any) =>
      !(
        fe?.geometry?.coordinates?.length === 0 ||
        (fe?.geometry?.type === GEOJSON_TYPES.Polygon &&
          fe.geometry.coordinates[0].length === 1)
      )
  );
}

export function cleanPolygonDuplicateCoordinates(features: Feature[]) {
  return features.map((fe: any) => {
    if (fe?.geometry?.type === GEOJSON_TYPES.Polygon) {
      const coordinates = fe.geometry.coordinates[0];
      return {
        ...fe,
        geometry: {
          ...fe.geometry,
          coordinates: [
            coordinates.filter((c: any, i: number) => {
              const prev = coordinates[i - 1];
              return !(
                prev &&
                Math.round(c[0]) === Math.round(prev[0]) &&
                Math.round(c[1]) === Math.round(prev[1])
              );
            }),
          ],
        },
      };
    }
    return fe;
  });
}

export function cleanPolygonsFeatures(features: Feature[]) {
  // will be used to run other function also
  return cleanSingleCoordPolygons(features);
}

export function cleanArrayIndentation(feature: Feature): Feature {
  if (Array.isArray(feature) && feature.length && feature.length > 0) {
    return cleanArrayIndentation(feature[0]);
  } else {
    return feature;
  }
}

export function cleanCoordinates(
  type: string,
  coordinates: Position | Position[] | Position[][] | Position[][][]
) {
  return type === GEOJSON_TYPES.Polygon &&
    coordinates?.length &&
    (coordinates as Position[])[0][0] &&
    !Array.isArray((coordinates as Position[])[0][0])
    ? [coordinates]
    : coordinates;
}

export function getArea(
  types: string[],
  geometryType: string,
  layerCoords: ILatLng[][][] | ILatLng[][] | ILatLng[],
  dpi: number,
  scale_real: number,
  scale_drawing: number
): number {
  return types.includes(GEOJSON_TYPES.markup)
    ? 0
    : getAreaSquareInches(
        geometryType,
        layerCoords,
        dpi,
        scale_real,
        scale_drawing
      );
}

export function getPerimeter(
  types: string[],
  geometryType: string,
  layerCoords: ILatLng[][][] | ILatLng[][] | ILatLng[],
  width: number,
  bounds: Position[],
  dpi: number,
  scale_real: number,
  scale_drawing: number
) {
  return types.includes(GEOJSON_TYPES.markup)
    ? 0
    : getPerimeterInches(
        geometryType,
        layerCoords,
        width,
        bounds,
        dpi,
        scale_real,
        scale_drawing
      );
}

// UPDATES
export function recalculateAreaPerimeter(
  feature: Feature<FeatureTypesWithCoordinates>,
  view: IView,
  clMap: any
) {
  let updatedFeature = feature;
  const featureMeasures = getFeatureMeasures(updatedFeature, view, clMap);

  updatedFeature = {
    ...updatedFeature,
    properties: {
      ...updatedFeature.properties,
      ...featureMeasures,
    },
  };

  return updatedFeature;
}

export function updateAllFeaturesAreaPerimeter(view: IView, clMap: any) {
  return {
    ...view,
    features: view.features.map((fe: any) =>
      recalculateAreaPerimeter(fe, view, clMap)
    ),
  };
}

export function middlePoint(a: Position, b: Position): Position {
  return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
}

function removeHoleIfNecessary(polygon: number[][], holeIndex: number) {
  const hole = polygon[holeIndex];
  if (hole.length <= 3) {
    polygon.splice(holeIndex, 1);
    return true;
  }
  return false;
}

function pruneMultiLineStringIfNecessary(geometry: any) {
  for (
    let lineStringIndex = 0;
    lineStringIndex < geometry.coordinates.length;
    lineStringIndex++
  ) {
    const lineString = geometry.coordinates[lineStringIndex];
    if (lineString.length === 1) {
      // Only a single position left on this LineString, so remove it (can't have Point in MultiLineString)
      geometry.coordinates.splice(lineStringIndex, 1);
      // Keep the index the same
      lineStringIndex--;
    }
  }
}

function pruneMultiPolygonIfNecessary(geometry: any) {
  for (
    let polygonIndex = 0;
    polygonIndex < geometry.coordinates.length;
    polygonIndex++
  ) {
    const polygon = geometry.coordinates[polygonIndex];
    const outerRing = polygon[0];

    // If the outer ring is no longer a polygon, remove the whole polygon
    if (outerRing.length <= 3) {
      geometry.coordinates.splice(polygonIndex, 1);
      // It was removed, so keep the index the same
      polygonIndex--;
    }

    for (let holeIndex = 1; holeIndex < polygon.length; holeIndex++) {
      if (removeHoleIfNecessary(polygon, holeIndex)) {
        // It was removed, so keep the index the same
        holeIndex--;
      }
    }
  }
}

function prunePolygonIfNecessary(geometry: any) {
  const polygon = geometry.coordinates;

  // If any hole is no longer a polygon, remove the hole entirely
  for (let holeIndex = 1; holeIndex < polygon.length; holeIndex++) {
    if (removeHoleIfNecessary(polygon, holeIndex)) {
      // It was removed, so keep the index the same
      holeIndex--;
    }
  }
}

export function pruneGeometryIfNecessary(geometry: any) {
  switch (geometry.type) {
    case GEOJSON_TYPES.Point:
      prunePolygonIfNecessary(geometry);
      break;
    case GEOJSON_TYPES.MultiLineString:
      pruneMultiLineStringIfNecessary(geometry);
      break;
    case GEOJSON_TYPES.MultiPolygon:
      pruneMultiPolygonIfNecessary(geometry);
      break;
    default:
      // Not downgradable
      break;
  }
}

interface ILineDefinition {
  slope: number;
  intercept: number | null;
}

interface IHessianLineDefinition {
  rho: number;
  theta: number;
}

export function getHessianLineDefinitionFromPoints(
  point1: Position,
  point2: Position
): IHessianLineDefinition {
  const [x1, y1] = point1;
  const [x2, y2] = point2;
  const theta = Math.atan((x1 - x2) / (y2 - y1));
  const rho = x1 * Math.cos(theta) + y1 * Math.sin(theta);
  return { rho, theta };
}

// https://math.stackexchange.com/a/1992170/1040588
export function getHessianLineIntersection(
  line1: IHessianLineDefinition,
  line2: IHessianLineDefinition
): Position | null {
  if (line1.theta === line2.theta) return null;

  const x =
    (line1.rho * Math.sin(line2.theta) - Math.sin(line1.theta) * line2.rho) /
    (Math.cos(line1.theta) * Math.sin(line2.theta) -
      Math.sin(line1.theta) * Math.cos(line2.theta));

  const y =
    (Math.cos(line1.theta) * line2.rho - line1.rho * Math.cos(line2.theta)) /
    (Math.cos(line1.theta) * Math.sin(line2.theta) -
      Math.sin(line1.theta) * Math.cos(line2.theta));

  return [x, y];
}

export function getHessianLineFromLineAndPoint(
  { theta }: IHessianLineDefinition,
  point: Position
): IHessianLineDefinition {
  const [x, y] = point;
  const rho = x * Math.cos(theta) + y * Math.sin(theta);

  return { theta, rho };
}

export function getEditSnappedAngles(feature: any, positionIndexes: any) {
  const cpI = positionIndexes; // current position indices
  const [currentCoords, len] = getCoordAtPosition(
    feature.geometry.coordinates,
    cpI
  );
  const ppILast = cpI[cpI.length - 1] === 0 ? len - 2 : cpI[cpI.length - 1] - 1;
  const npILast = cpI[cpI.length - 1] === len - 2 ? 0 : cpI[cpI.length - 1] + 1;
  const ppI = [...cpI.slice(0, cpI.length - 1), ppILast]; // previous
  const npI = [...cpI.slice(0, cpI.length - 1), npILast]; // next

  const [nextCoords, _] = getCoordAtPosition(feature.geometry.coordinates, npI);
  const [prevCoords, __] = getCoordAtPosition(
    feature.geometry.coordinates,
    ppI
  );

  const paddedCc = currentCoords.map(divide(DEFAULT_PADDING));
  const paddedNc = nextCoords.map(divide(DEFAULT_PADDING));
  const paddedPc = prevCoords.map(divide(DEFAULT_PADDING));

  const b1 = Math.round(bearing(paddedPc, paddedCc));
  const b2 = Math.round(bearing(paddedNc, paddedCc));
}

export function calculateDistanceForTooltip({
  positionA,
  positionB,
  modeConfig,
}: any) {
  const { measurementCallback, calcDistance, view } = modeConfig || {};

  const distance = calcDistance(view, positionA, positionB);

  if (!!measurementCallback) {
    measurementCallback(distance);
  }

  return distance;
}

export function calculateDistanceForSegments(
  coordinates: [Number, Number],
  modeConfig: any
): number {
  const segments = [];
  for (let i = 0; i < coordinates.length - 1; i++) {
    segments.push([coordinates[i], coordinates[i + 1]]);
  }

  const distances = segments.map((segment) =>
    calculateDistanceForTooltip({
      positionA: segment[0],
      positionB: segment[1],
      modeConfig,
    })
  );

  const distance = distances.reduce((a, b) => a + b, 0);

  return distance;
}

export function createSnapTargets(data: any, selectedIndexes: any) {
  const snapHandles = [];
  const selectedIndicesSet = new Set(selectedIndexes);

  for (let i = 0; i < data.length; i++) {
    if (!selectedIndicesSet.has(i)) {
      const { geometry, properties } = data[i];
      const types = properties?.types || [];
      if (
        !types.includes(GEOJSON_TYPES.markup) &&
        !types.includes(GEOJSON_TYPES.dimensionLine)
      ) {
        snapHandles.push(
          ...getEditHandlesForGeometry(
            geometry,
            i,
            "snap-target",
            true,
            properties?.id
          )
        );
      }
    }
  }

  return new KDBush(snapHandles);
}

// not used anymore but can still be useful
// function projectPointOntoCircle(
//   point: [number, number],
//   center: [number, number],
//   radius: number,
//   reverse = false
// ): [number, number] {
//   const vectorX = point[0] - center[0];
//   const vectorY = point[1] - center[1];

//   const vectorLength = Math.sqrt(vectorX * vectorX + vectorY * vectorY);

//   const normalizedVectorX = vectorX / vectorLength;
//   const normalizedVectorY = vectorY / vectorLength;

//   const projectedX =
//     center[0] + (reverse ? -normalizedVectorX : normalizedVectorX) * radius;
//   const projectedY =
//     center[1] + (reverse ? -normalizedVectorY : normalizedVectorY) * radius;

//   return [projectedX, projectedY];
// }

function generatePoints(
  p1: [number, number],
  p2: [number, number],
  center: [number, number],
  radius = 10,
  s = 10,
  project = true
): Array<[number, number]> {
  if (s === 0) return [];
  const points: Array<[number, number]> = [];

  if (!project) {
    for (let i = 1; i <= s; i++) {
      const t = i / s;
      const x = p1[0] + t * (p2[0] - p1[0]);
      const y = p1[1] + t * (p2[1] - p1[1]);
      points.push([x, y]);
    }

    return points;
  } else {
    const dx1 = p1[0] - center[0];
    const dy1 = p1[1] - center[1];
    const dx2 = p2[0] - center[0];
    const dy2 = p2[1] - center[1];
    const crossProduct = dx1 * dy2 - dx2 * dy1;
    let angleStart = Math.atan2(dy1, dx1);
    let angleEnd = Math.atan2(dy2, dx2);

    if (crossProduct > 0) {
      if (angleStart > angleEnd) {
        angleEnd += 2 * Math.PI;
      }
    } else {
      if (angleStart < angleEnd) {
        angleStart += 2 * Math.PI;
      }
    }

    for (let i = 1; i <= s; i++) {
      const angle = angleStart + (i / s) * (angleEnd - angleStart);
      const x = center[0] + radius * Math.cos(angle);
      const y = center[1] + radius * Math.sin(angle);
      points.push([x, y]);
    }

    return points;
  }
}

function getCircle(
  startCoords: [number, number],
  controlCoords: [number, number],
  endCoords: [number, number]
) {
  const D =
    2 *
    (startCoords[0] * (controlCoords[1] - endCoords[1]) +
      controlCoords[0] * (endCoords[1] - startCoords[1]) +
      endCoords[0] * (startCoords[1] - controlCoords[1]));
  const Ux =
    ((startCoords[0] ** 2 + startCoords[1] ** 2) *
      (controlCoords[1] - endCoords[1]) +
      (controlCoords[0] ** 2 + controlCoords[1] ** 2) *
        (endCoords[1] - startCoords[1]) +
      (endCoords[0] ** 2 + endCoords[1] ** 2) *
        (startCoords[1] - controlCoords[1])) /
    D;
  const Uy =
    ((startCoords[0] ** 2 + startCoords[1] ** 2) *
      (endCoords[0] - controlCoords[0]) +
      (controlCoords[0] ** 2 + controlCoords[1] ** 2) *
        (startCoords[0] - endCoords[0]) +
      (endCoords[0] ** 2 + endCoords[1] ** 2) *
        (controlCoords[0] - startCoords[0])) /
    D;

  const radius = Math.sqrt(
    (Ux - startCoords[0]) ** 2 + (Uy - startCoords[1]) ** 2
  );

  return {
    center: [Ux, Uy],
    radius,
  };
}

export function getArcPoints(
  startCoords: [number, number],
  controlCoords: [number, number],
  endCoords: [number, number],
  nbOfSegments = 10,
  autoMaticSegments = true
): Array<[number, number]> {
  const points: Array<[number, number]> = [];
  let segments = nbOfSegments;

  const maxDistance = Math.round(
    Math.sqrt(
      (startCoords[0] - endCoords[0]) ** 2 +
        (startCoords[1] - endCoords[1]) ** 2
    )
  );
  const d1 = Math.round(
    Math.sqrt(
      (startCoords[0] - controlCoords[0]) ** 2 +
        (startCoords[1] - controlCoords[1]) ** 2
    )
  );
  const d2 = Math.round(
    Math.sqrt(
      (controlCoords[0] - endCoords[0]) ** 2 +
        (controlCoords[1] - endCoords[1]) ** 2
    )
  );

  if (d1 >= maxDistance || d2 >= maxDistance) {
    return [];
  }

  const maxD1D2 = Math.max(d1, d2);

  if (autoMaticSegments) {
    segments = Math.min(Math.max(Math.round(maxD1D2 / 200), 7), 40);
  }

  const { center, radius } = getCircle(
    startCoords,
    controlCoords,
    endCoords
  ) as any;

  const ratio = d2 / d1;
  const s1 = Math.round(segments / (1 + ratio));
  const s2 = segments - s1;

  if (radius && radius < 200000) {
    points.push(
      ...generatePoints(startCoords, controlCoords, center, radius, s1)
    );
    points.push(
      ...generatePoints(controlCoords, endCoords, center, radius, s2)
    );
    return points;
  }

  points.push(
    ...generatePoints(startCoords, controlCoords, center, radius, s1, false)
  );
  points.push(
    ...generatePoints(controlCoords, endCoords, center, radius, s2, false)
  );

  return points;
}

export function getQuadraticPoints(
  startCoords: [number, number],
  controlCoords: [number, number],
  endCoords: [number, number],
  nbOfSegments: number = 10,
  autoMaticSegments = true
): Array<[number, number]> {
  const points: Array<[number, number]> = [];
  let segments = nbOfSegments;

  const controlX = 2 * controlCoords[0] - (startCoords[0] + endCoords[0]) / 2;
  const controlY = 2 * controlCoords[1] - (startCoords[1] + endCoords[1]) / 2;

  const dist1 = Math.sqrt(
    (startCoords[0] - controlCoords[0]) ** 2 +
      (startCoords[1] - controlCoords[1]) ** 2
  );
  const dist2 = Math.sqrt(
    (controlCoords[0] - endCoords[0]) ** 2 +
      (controlCoords[1] - endCoords[1]) ** 2
  );
  const maxD1D2 = Math.max(dist1, dist2);

  if (autoMaticSegments) {
    segments = Math.min(Math.max(Math.round(maxD1D2 / 200), 7), 40);
  }

  new Array(segments)
    .fill(0)
    .map((_, i) => (i + 1) / segments)
    .filter(Boolean)
    .forEach((t) => {
      const x =
        (1 - t) ** 2 * startCoords[0] +
        2 * (1 - t) * t * controlX +
        t ** 2 * endCoords[0];
      const y =
        (1 - t) ** 2 * startCoords[1] +
        2 * (1 - t) * t * controlY +
        t ** 2 * endCoords[1];

      points.push([x, y]);
    });

  return points;
}

function getCirclePoints(
  startCoords: [number, number],
  controlCoords: [number, number],
  endCoords: [number, number],
  nbOfSegments: number = 10,
  autoMaticSegments = true
): Array<[number, number]> {
  const points: Array<[number, number]> = [];
  let segments = nbOfSegments;

  const centerX = (startCoords[0] + endCoords[0]) / 2;
  const centerY = (startCoords[1] + endCoords[1]) / 2;

  const radius =
    Math.sqrt(
      Math.pow(endCoords[0] - startCoords[0], 2) +
        Math.pow(endCoords[1] - startCoords[1], 2)
    ) / 2;

  if (autoMaticSegments) {
    segments = Math.min(Math.max(Math.round(radius / 50), 15), 40);
  }

  const angleOffset = Math.atan2(
    startCoords[1] - centerY,
    startCoords[0] - centerX
  );

  for (let i = 0; i < segments; i++) {
    const angle = angleOffset + (i / segments) * 2 * Math.PI;
    const x = centerX + radius * Math.cos(angle);
    const y = centerY + radius * Math.sin(angle);
    points.push([x, y]);
  }

  points.push(startCoords);

  return points;
}

function getRectangeCoordinates(
  startCoords: [number, number],
  controlCoords: [number, number],
  endCoords: [number, number],
  nbOfSegments: number = 10
): Array<[number, number]> {
  const points: Array<[number, number]> = [];

  points.push(startCoords);
  points.push([startCoords[0], endCoords[1]]);
  points.push(endCoords);
  points.push([endCoords[0], startCoords[1]]);
  points.push(startCoords);

  return points;
}

export function getArcFunction(type: number) {
  switch (type) {
    case ARC_MODE_CIRCLE:
      return getArcPoints;
    case MODE_SPHERE:
      return getCirclePoints;
    case ARC_MODE_QUADRATIC:
      return getQuadraticPoints;
    case MODE_RECTANGLE:
      return getRectangeCoordinates;
    default:
      return getArcPoints;
  }
}

export function getDistanceTooltip(
  props: FeatureProps,
  feature: any,
  context: Context,
  colorMap?: any,
  isPipeline: boolean = false
): any[] {
  const { modeConfig }: any = props;

  if (
    modeConfig?.guideType === GEOJSON_TYPES.markup &&
    !modeConfig?.showDimensionLine
  )
    return [];

  const coordinates = feature?.geometry?.coordinates || [];
  let distance = null;
  let lastCoordinates = null;

  if (context._isArcMode) {
    if ([ARC_MODE_CIRCLE, ARC_MODE_QUADRATIC].includes(context._tempArcMode)) {
      lastCoordinates =
        coordinates?.length > 0
          ? Array.isArray(last(coordinates[0]))
            ? coordinates[0].slice(context._tempArcStartIndex)
            : coordinates.slice(context._tempArcStartIndex)
          : null;

      if (lastCoordinates?.length) {
        distance = calculateDistanceForSegments(lastCoordinates, modeConfig);
      }
    }
  } else {
    lastCoordinates =
      coordinates?.length > 0
        ? Array.isArray(last(coordinates[0]))
          ? coordinates[0].slice(-2)
          : coordinates.slice(-2)
        : null;

    if (lastCoordinates?.length) {
      distance = calculateDistanceForTooltip({
        positionA: lastCoordinates[0],
        positionB: lastCoordinates[1],
        modeConfig,
      });
    }
  }

  if (distance) {
    const lastCoordinate: any = last(lastCoordinates) || null;

    let tooltipText = " " + prettyInches(distance, true, modeConfig.useMetrics);

    if (isPipeline) {
      const featureClass = colorMap?.[feature?.properties?.className];
      if (featureClass) {
        tooltipText = `${
          featureClass?.isStatic
            ? ""
            : featureClass?.size && featureClass.size in PIPELINE_WIDTHS_LABELS
            ? PIPELINE_WIDTHS_LABELS[featureClass.size]
            : featureClass.size + '"'
        } ${featureClass.label} ${prettyInches(
          distance,
          true,
          modeConfig.useMetrics
        )}`;
      } else {
        tooltipText =
          (PIPELINE_WIDTHS_MAP[feature?.properties?.pipelineWidth] ?? "") +
          " " +
          prettyInches(distance, true, modeConfig.useMetrics);
      }
    }

    return [
      {
        position: lastCoordinate,
        text: tooltipText,
        size: 12,
        anchor: "middle",
        baseline: "bottom",
        offset: [0, -10],
        color: RGBA_COLORS.BLACK,
        backgroundColor: [...RGB_COLORS.BLACK, 20],
      },
    ];
  }

  return [];
}

function getTextColor(color: any) {
  const luminance =
    (0.299 * color[0] + 0.587 * color[1] + 0.114 * color[2]) / 255;

  return luminance > 0.6 ? RGBA_COLORS.BLACK : RGBA_COLORS.WHITE;
}

export function getStateTooltip(props: FeatureProps, context: Context) {
  const mapCoords = props?.lastPointerMoveEvent?.mapCoords;
  const cls: any = props?.modeConfig?.activeClassification;
  const backgroundColor = [
    ...(cls?.colorStyle?.color || RGB_COLORS.PURPLE),
    255,
  ];
  const color = getTextColor(cls?.colorStyle?.color || RGB_COLORS.PURPLE);

  if (!context._isArcMode) return [];

  const infoText =
    context._tempArcMode === MODE_RECTANGLE
      ? " Rectangle "
      : context._tempArcMode === MODE_SPHERE
      ? " Circle "
      : " Arc ";

  const stateInfo = {
    position: [mapCoords[0], mapCoords[1]],
    text: infoText,
    size: 10,
    color,
    anchor: "start",
    baseline: "top",
    offset: [10, 10],
    backgroundColor,
  };

  return [stateInfo];
}

function calculateArrowheadPoints(
  start: number[],
  end: number[],
  degrees: number,
  length: number
): [number[], number[]] {
  const angle = Math.atan2(end[1] - start[1], end[0] - start[0]) + Math.PI;
  const leftAngle = angle + degrees * (Math.PI / 180);
  const rightAngle = angle - degrees * (Math.PI / 180);
  const leftPoint: number[] = [
    end[0] + length * Math.cos(leftAngle),
    end[1] + length * Math.sin(leftAngle),
  ];

  const rightPoint: number[] = [
    end[0] + length * Math.cos(rightAngle),
    end[1] + length * Math.sin(rightAngle),
  ];

  return [leftPoint, rightPoint];
}

export function makeArrowFromLine(
  feature: any,
  degrees: number,
  length: number = 0
): Feature {
  const start = feature.geometry.coordinates[0];
  const end = feature.geometry.coordinates[1];

  if (!length) {
    length = calculateDistance(start, end) / 5;
  }

  const arrowPoints = calculateArrowheadPoints(start, end, degrees, length);

  const arrowMultiLineString: Feature = {
    type: "Feature",
    geometry: {
      type: GEOJSON_TYPES.MultiLineString,
      coordinates: [
        [start, end],
        [arrowPoints[1], end, arrowPoints[0]],
      ],
    },
    properties: {
      ...feature.properties,
    },
  };
  return arrowMultiLineString;
}

const TEXT_BOX_ALIGNMENT = {
  BOTTOM: "BOTTOM",
  TOP: "TOP",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};

export function determineTextBoxAlignment(
  coords: [Position, Position]
): valueof<typeof TEXT_BOX_ALIGNMENT> {
  const [lineStart, lineEnd] = coords;

  const isLineHorizontal =
    Math.abs(head(lineStart) - head(lineEnd)) >
    Math.abs(last(lineStart) - last(lineEnd));

  const isLineLTR = head(lineStart) < head(lineEnd);
  const isLineTTB = last(lineStart) > last(lineEnd);

  return isLineHorizontal
    ? isLineLTR
      ? TEXT_BOX_ALIGNMENT.RIGHT
      : TEXT_BOX_ALIGNMENT.LEFT
    : isLineTTB
    ? TEXT_BOX_ALIGNMENT.BOTTOM
    : TEXT_BOX_ALIGNMENT.TOP;
}

export function getTextPolygonCoordinates(
  coords: [Position, Position],
  size: number
): [[Position, Position, Position, Position, Position]] {
  const [, lineEnd] = coords;

  const textBoxAlignment = determineTextBoxAlignment(coords);

  const width = size;
  const height = size * 0.6;

  const alignmentConditions = [
    [TEXT_BOX_ALIGNMENT.RIGHT, [head(lineEnd), last(lineEnd) + height / 2]],
    [
      TEXT_BOX_ALIGNMENT.LEFT,
      [head(lineEnd) - width, last(lineEnd) + height / 2],
    ],
    [TEXT_BOX_ALIGNMENT.BOTTOM, [head(lineEnd) - width / 2, last(lineEnd)]],
    [
      TEXT_BOX_ALIGNMENT.TOP,
      [head(lineEnd) - width / 2, last(lineEnd) + height],
    ],
  ];

  const firstPoint: Position = cond(
    alignmentConditions.map(([alignment, value]) => [
      equals(alignment),
      always(value),
    ])
  )(textBoxAlignment);

  return [
    [
      firstPoint,
      [head(firstPoint) + width, last(firstPoint)],
      [head(firstPoint) + width, last(firstPoint) - height],
      [head(firstPoint), last(firstPoint) - height],
      firstPoint,
    ],
  ];
}

function randomBetween(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

function createCircle(center: number[], radius: number, steps: number) {
  const coords = [];
  for (let i = 0; i < steps; i++) {
    const angle = (((i * 360) / steps) * Math.PI) / 180;
    const dx = radius * Math.cos(angle);
    const dy = radius * Math.sin(angle);
    coords.push([center[0] + dx, center[1] + dy]);
  }
  // Ensure the path is closed by repeating the first point
  coords.push(coords[0]);

  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [coords],
    },
  };
}

function createCircleAlongLine(
  start: number[],
  end: number[],
  radiusMin: number,
  radiusMax: number
) {
  const totalLength = calculateDistance(start, end);

  let currentDistance = 0;
  let circles = [];

  while (currentDistance < totalLength) {
    const radius = Math.round(randomBetween(radiusMin, radiusMax));

    const newPoint = [
      start[0] + (currentDistance / totalLength) * (end[0] - start[0]),
      start[1] + (currentDistance / totalLength) * (end[1] - start[1]),
    ];

    let circle = createCircle(newPoint, radius, 20);
    circles.push(circle);
    currentDistance += radius * 1.7;
  }

  return circles;
}

export function getCloudPolygonCoordinates(boxCoordinates: any[]) {
  const boxFeature: any = {
    type: "Feature",
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: boxCoordinates,
    },
    properties: {},
  };

  let mergeFeatures: any = [];

  for (let i = 0; i < boxCoordinates[0].length - 1; i++) {
    const start = boxCoordinates[0][i];
    const end = boxCoordinates[0][i + 1];
    const circles = createCircleAlongLine(start, end, 80, 140);
    mergeFeatures = mergeFeatures.concat(circles);
  }

  let merged: any = boxFeature;
  for (let i = 0; i < mergeFeatures.length; i++) {
    merged = turfUnion(merged, mergeFeatures[i]);
  }

  if (merged.geometry.type === GEOJSON_TYPES.Polygon) {
    const buffered = turfSmooth(merged, { iterations: 1 });
    if (buffered.features.length > 0) {
      return buffered.features[0].geometry.coordinates;
    }

    return merged.geometry.coordinates;
  }
  return boxCoordinates;
}

export function getMarkupLineCoords(geometryCoords: any, pointCoords: any) {
  if (!Array.isArray(geometryCoords) || geometryCoords.length < 2) {
    console.warn(
      "Invalid geometryCoords: at least two coordinates are required."
    );
    return null; // Or handle as needed
  }

  const lineCoords = geometryCoords
    .slice(0, 4)
    .map((coord: Position, index: number) => {
      const nextCoord = geometryCoords[index + 1];
      if (!nextCoord) return null;

      const centerToNext = [
        (coord[0] + nextCoord[0]) / 2,
        (coord[1] + nextCoord[1]) / 2,
      ];

      return {
        coord: centerToNext,
        distance: Math.sqrt(
          Math.pow(pointCoords[0] - centerToNext[0], 2) +
            Math.pow(pointCoords[1] - centerToNext[1], 2)
        ),
        index,
      };
    })
    .filter(Boolean)
    .sort((a: any, b: any) => a.distance - b.distance);

  return lineCoords[0]?.coord || null;
}

export function makeDimensionLine(feature: any): Feature {
  if (feature?.properties?.types?.includes(GEOJSON_TYPES.dimensionLine)) {
    const start = feature.geometry.coordinates[0];
    const end = feature.geometry.coordinates[1];
    const startAn = calculateArrowheadPoints(start, end, 90, 40);
    const endAn = calculateArrowheadPoints(end, start, 90, 40);

    const arrowMultiLineString: Feature = {
      type: "Feature",
      geometry: {
        type: GEOJSON_TYPES.MultiLineString,
        coordinates: [
          [start, end],
          [startAn[1], startAn[0]],
          [endAn[1], endAn[0]],
        ],
      },
      properties: {
        ...feature.properties,
      },
    };
    return arrowMultiLineString;
  }

  return feature;
}

export function getCoordsMinMaxBounds(coordinates: any) {
  // create bounding box with x and y always being the top left corner
  const min_x = Math.round(Math.min(...coordinates.map((c: any) => c[0])));
  const min_y = Math.round(Math.min(...coordinates.map((c: any) => c[1])));
  const max_x = Math.round(Math.max(...coordinates.map((c: any) => c[0])));
  const max_y = Math.round(Math.max(...coordinates.map((c: any) => c[1])));

  const width = max_x - min_x;
  const height = max_y - min_y;

  return [min_x, min_y, width, height];
}

export function snapToLength(
  p1: [number, number] | [number, number, number],
  p2: [number, number] | [number, number, number],
  referenceInchesPerPixels: number,
  lengths: number[]
): [number, number] | [number, number, number] {
  const is3D = p1.length === 3 && p2.length === 3;

  const distance = is3D
    ? Math.sqrt(
        Math.pow(p2[0] - p1[0], 2) +
          Math.pow(p2[1] - p1[1], 2) +
          Math.pow((p2[2] || 0) - (p1[2] || 0), 2)
      )
    : Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2));

  const closestLength = lengths
    .map((l) => l / referenceInchesPerPixels)
    .reduce((prev, curr) =>
      Math.abs(curr - distance) < Math.abs(prev - distance) ? curr : prev
    );

  const ratio = closestLength / distance;

  return is3D
    ? [
        p1[0] + (p2[0] - p1[0]) * ratio,
        p1[1] + (p2[1] - p1[1]) * ratio,
        (p1[2] || 0) + ((p2[2] || 0) - (p1[2] || 0)) * ratio,
      ]
    : [p1[0] + (p2[0] - p1[0]) * ratio, p1[1] + (p2[1] - p1[1]) * ratio];
}

export function getSnappedFeatureToLengths(
  feature: any,
  referenceInchesPerPixels: number,
  lengths: number[] = PIPELINE_LENGTHS
) {
  if (feature?.geometry?.type === GEOJSON_TYPES.LineString) {
    const coordinates = feature.geometry.coordinates;

    const lastCoordinate = coordinates[coordinates.length - 1];
    const secondLastCoordinate = coordinates[coordinates.length - 2];

    const snappedLastCoordinate = snapToLength(
      secondLastCoordinate,
      lastCoordinate,
      referenceInchesPerPixels,
      lengths
    );

    return {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: [...coordinates.slice(0, -1), snappedLastCoordinate],
      },
    };
  } else {
    return feature;
  }
}

export function updatePipeLineFeatureCoordinates(
  feature: any,
  handle: any,
  mapCoords: [number, number],
  colorMap: any,
  referenceInchesPerPixels: number,
  isShiftKey?: boolean,
  isLengthSnapping?: boolean
): any {
  const coordinates = feature.geometry.coordinates;
  const targetPositionIndex = handle.properties.positionIndexes[0];
  const originalTargetPositionIndex = targetPositionIndex == 0 ? 1 : 0;
  const isReverse = targetPositionIndex === 0;

  if (coordinates.length === 2) {
    const target = coordinates[targetPositionIndex];
    const origin = coordinates[originalTargetPositionIndex];
    const newTarget = [mapCoords[0], mapCoords[1], target[2] || 0];

    const snaped = isShiftKey
      ? newTarget
      : snapToDegAbs(origin, [newTarget], SNAP_ANGLES_S);

    const newCoordinates = [...coordinates];
    newCoordinates[targetPositionIndex] = snaped || newTarget;

    let newFeature = {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: isReverse ? newCoordinates.reverse() : newCoordinates,
      },
    };

    if (!isShiftKey && isLengthSnapping) {
      newFeature = getSnappedFeatureToLengths(
        newFeature,
        referenceInchesPerPixels
      );
    }

    const pipelineFeature = pipelineToPolygon(
      newFeature,
      colorMap,
      referenceInchesPerPixels
    );
    if (pipelineFeature) {
      return pipelineFeature;
    }
  }

  return feature;
}
